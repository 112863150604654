import { Pie } from "react-chartjs-2";
import /* { Chart as ChartJS } from */ "chart.js/auto";

import React from "react";

function PieChart(props) {
  return (
    <div style={{ width: 350 }} className="mx-auto my-2">
      <h4 className="text-center">{props.label}</h4>
      <Pie data={props.chartData} />
    </div>
  );
}

export default PieChart;
