//status indicate is the department needed on the quarter, 1 is on/0 is off
export const departments = [
  {
    id: 1,
    status: 2,
    title: "Customer Support",
  },
  {
    id: 2,
    status: 2,
    title: "My Support | Banking Ops",
  },
  {
    id: 3,
    status: 2,
    title: "SSC | Banking Ops",
  },
];
