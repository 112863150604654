import React, { useState, useEffect, useRef, useContext } from "react";
import Axios from "axios";
//import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

///categorieze has unique request on its button instead
import CategorizeBtn from "./categorize-response/categorizeButton";
import ResetBtn from "./category-reset/resetButton";
//context
import { DepartmentContext } from "../../../../../../Context/departmentContext";

import "../../../../../../Styles/Dept/datatable.scss";

const Datatable = (props) => {
  const { client } = useContext(DepartmentContext);
  const [rows, setRows] = useState([]);

  const effectRan = useRef(false);
  useEffect(() => {
    if (effectRan.current === false) {
      Axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/forms-gxbank/${props.table_id}+${client}`
      )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          //console.log(data.data);
          const revisedDatatype = data.data.map((data) => {
            return {
              ...data,
              questions: data.questions[props.index],
              category: data.categories[props.index],
            };
          });
          setRows(revisedDatatype);
        })
        .catch((err) => {
          console.log(err.message);
          //navigate("/404");
        });
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  const columns = [
    {
      field: "categorize",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <CategorizeBtn
            params={params}
            question={props.question}
            category_id={props.category_id}
            checkbox_question_db={props.checkbox_question_db}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 70,
      renderCell: (params) => {
        return (
          <ResetBtn
            params={params}
            category_id={props.category_id}
            checkbox_question_db={props.checkbox_question_db}
          />
        );
      },
    },
    { field: "id", headerName: "No.", width: 50, resizable: true },
    { field: "editor_name", headerName: "Editor", width: 110 },
    { field: "full_name", headerName: "Agent Name", width: 140 },

    {
      field: "questions",
      headerName: `${props.question}`,
      width: 300,
    },
    {
      field: "category",
      headerName: `${props.question} Categories`,
      width: 300,
    },
  ];

  const noCategoriesColumns = [
    { field: "id", headerName: "No.", width: 50, resizable: true },
    { field: "editor_name", headerName: "Editor", width: 110 },
    { field: "full_name", headerName: "Agent Name", width: 140 },

    { field: "questions", headerName: `${props.question}`, width: 300 },
  ];

  const [colMode, setColMode] = useState(true);

  useEffect(() => {
    if (props.category_id == 13) {
      setColMode(!colMode);
    } else if (props.actions == false) {
      setColMode(!colMode);
    }
  }, [props.actions, props.category_id]);

  return (
    <div className="datatable">
      <DataGrid
        rows={rows}
        columns={colMode ? columns : noCategoriesColumns}
        pageSize={50}
        rowsPerPageOptions={[5]}
        resizable={true}
        //checkboxSelection
      />
    </div>
  );
};

export default Datatable;
