import { useState, useEffect, useRef } from "react";
import Axios from "axios";
import BarChart from "./barChart";

export default function BarChartRequest(props) {
  const effectRan = useRef(false);
  //chart label states
  //if datasets is setState not equal value after, data will not be displayed. colors off etc.
  //better to leave empty array and set it later
  const [chartPercentage, setchartPercentage] = useState([]);
  const [chartData, setchartData] = useState({
    labels: "",
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
      },
    ],
  });

  //console.log(props);
  useEffect(() => {
    if (effectRan.current === false) {
      Axios.get(
        //table_id + checkbox_db_id
        `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/bar-chart/${props.chart_id}+${props.category_id}`
      ).then((response) => {
        console.log(response.data);
        //const charts = [];
        const chartData = response.data.chartData;
        const percentageData = response.data.chartPercentage;
        //for (const key of chartData) charts.push(key);

        setchartData({
          labels: chartData.map((data) => data.category),
          datasets: [
            {
              label: props.label,
              data: chartData.map((data) => data.responses),
              backgroundColor: props.colorCode,
            },
          ],
        });
        setchartPercentage(percentageData);
      });

      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  return (
    <div>
      <BarChart chartData={chartData} label={props.label} />
      <div className="container alert alert-info">
        <h6>{props.label}</h6>
        <div className="row text-center">
          <div className="col">
            {chartPercentage.map((data) => {
              return (
                <div>
                  <h4>{data.category + ": " + data.percentage + "%"}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
