import React, { useContext, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarLeftExpand,
} from "react-icons/tb";
import { IconContext } from "react-icons/lib";
import { useNavigate } from "react-router-dom";
import TopNotchLogo from "../../Assets/topnotch_logo.png";
import Tabs from "./Tabs";
import Dropend from "./Dropend";
import { AuthContext } from "../../Context/AuthContext";
import { DepartmentContext } from "../../Context/departmentContext";
import { clients as ClientName } from "../../constants/client";

import "../../Styles/Home/sidebar.scss";

const Sidebar = () => {
  let navigate = useNavigate();

  const letters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  //request log Out
  //replace with useContext() logout
  const onLogout = (event) => {
    event.preventDefault();
    Axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/logOut`)
      .then((response) => {
        //console.log(response.data.success);
        if (response.data.logOut === false) {
          navigate("/");
          window.location.reload(false);
        }
      })
      .catch(() => {
        navigate("/403");
      });
  };

  const { authState } = useContext(AuthContext);
  const { client } = useContext(DepartmentContext);

  const [toggleExpand, setToggleExpand] = useState(true);

  const [clientLabel, setClientLabel] = useState(ClientName[client].name);
  const getClientLabelHandler = (data) => {
    setClientLabel(data);
  };

  return (
    <>
      <div className="sidebar" style={toggleExpand ? {} : { display: "none" }}>
        {/* content-1 */}
        <div>
          <div className="top">
            <div className="imagelogodiv">
              <img
                className="imagelogo"
                src={TopNotchLogo}
                alt="topnotch_logo"
              />
            </div>
            <span className="logo">Catch-Up System</span>
            <span className="client">{clientLabel}</span>
          </div>

          {/* center */}
          <div className="center">
            {/* <hr /> */}
            <ul>
              {authState.privilege === false && (
                <Link to="/">
                  <li>
                    <span>Landing page</span>
                  </li>
                </Link>
              )}
              {authState.privilege === true && (
                <>
                  <Link to="/dashboard">
                    <li>
                      <span>Dashboard</span>
                    </li>
                  </Link>
                </>
              )}
              {authState.status === true && (
                <>
                  <li>
                    <Dropend onSaveClient={getClientLabelHandler}>
                      <span>Change Client</span>
                    </Dropend>
                  </li>
                  {/* Collapsable sidebar */}
                  {/* switch 1 or 2 */}
                  <hr />
                  <p className="title">Departments</p>
                  {letters.map((letter) => {
                    return <Tabs level={letter} />;
                  })}
                </>
              )}
            </ul>
          </div>
        </div>

        {/* content-2 */}
        <div className="content-2">
          {authState.privilege === true && (
            <div>
              <hr />
              <p className="title">Master Settings</p>
              <Link to="/userAuth">
                <li>
                  <span>Create User Account</span>
                </li>
              </Link>
              <Link to="/userPrivileges">
                <li>
                  <span>Change User Privileges</span>
                </li>
              </Link>
              <Link to="/Admin/CreateNewClient">
                <li>
                  <span>Create New Client</span>
                </li>
              </Link>
            </div>
          )}

          {/* Auth */}
          {authState.status === false && (
            <div>
              <p className="title">USER</p>
              <Link to="/userAuth">
                <li>
                  <span>Log In</span>
                </li>
              </Link>
            </div>
          )}
          {authState.status === true && (
            <li className="d-flex justify-content-center my-3">
              <Form onSubmit={onLogout}>
                <Button type="submit">Log Out</Button>
              </Form>
            </li>
          )}
        </div>
      </div>

      <div className="collapse-btn mt-5 ms-1">
        <div
          onClick={() => {
            setToggleExpand((prev) => !prev);
          }}
        >
          <IconContext.Provider value={{ color: "#7072c1", size: "2em" }}>
            {toggleExpand ? (
              <TbLayoutSidebarLeftCollapse />
            ) : (
              <TbLayoutSidebarLeftExpand />
            )}
          </IconContext.Provider>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
