import React, { Fragment } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

import { Modal, Button } from "react-bootstrap";
import {
  BiError,
  BiMessageCheck,
  /* BiMinusCircle,
  BiCheck, */
} from "react-icons/bi";
import { toast } from "react-toastify";

function DeleteTableModal(props) {
  let navigate = useNavigate();

  const deleteTable = () => {
    Axios.delete(
      `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/delete-old-session-caution/${props.value}`
    )
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          return toast.error(
            <Fragment>
              <BiError /> <span>{response.data.error}</span>
            </Fragment>
          );
        } else {
          /* change dept id */
          navigate("/department/1");
          return toast.success(
            <Fragment>
              <BiMessageCheck /> <span>{response.data.success}</span>
            </Fragment>
          );
        }
      })
      .catch((error) => {
        alert(error.message);
        navigate("/403");
      });
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-bg-danger rounded-2 p-2">
          Caution. Deleting this Session!{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title className="p-2">
          This action will delete ALL DATA in the current Table ID :{" "}
          {props.value}. Lost data cannot be recovered!
        </Modal.Title>
        <Modal.Title className="text-danger p-2 mt-2">
          Proceed with consideration.
        </Modal.Title>
        <div className="d-flex justify-content-evenly mt-4">
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={props.handleClose && deleteTable}
            //type="submit"
            //form="createCatchupTable"
            //disabled={!userBasicInfoFormIsValid}
          >
            Confirm Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteTableModal;
