import React, { useContext, Fragment } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import useInput from "../../../../../Hooks/useInput";
import { AuthContext } from "../../../../../Context/AuthContext";
import { DepartmentContext } from "../../../../../Context/departmentContext";

import {
  //BiError,
  BiMessageCheck,
  BiMinusCircle,
  //BiCheck,
} from "react-icons/bi";
import { toast } from "react-toastify";

function FormModalTable(props) {
  //all pages needs axios credentials for session to work
  Axios.defaults.withCredentials = true;
  const { authState } = useContext(AuthContext);
  const { client } = useContext(DepartmentContext);

  let navigate = useNavigate();
  //console.log(props)
  //table state
  const {
    value: titleEntered,
    isValid: titleIsValid,
    valueChangeHandler: titleChange,
    inputBlurHandler: titleBlur,
    reset: titleReset,
  } = useInput();

  const tableFormHandler = (event) => {
    event.preventDefault();

    if (!titleIsValid) {
      return;
    } else {
      const randomID = Math.floor(Math.random() * 10000000).toString();

      Axios.post(
        `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/create-new-session`,
        {
          table_id: randomID,
          user_id: authState.id,
          title: titleEntered,
          department_id: props.department_id,
          client_id: client,
        }
      )
        .then((response) => {
          //deprecated lift up
          //props.onCreateTable(new Date(), titleEntered, randomID);

          if (response.data.error) {
            return toast.error(
              <Fragment>
                <BiMinusCircle /> <span>{response.data.error}</span>
              </Fragment>
            );
          } else {
            return toast.success(
              <Fragment>
                <BiMessageCheck /> <span>{response.data.success}</span>
              </Fragment>
            );
          }
        })
        .catch((error) => {
          alert(error.message);
          navigate("/403");
        });
      //temporary solution for tanStack
      //nullifies the create request
      //window.location.reload(false);
    }
    //emptying fields
    titleReset();
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Catchup Session</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={tableFormHandler} id="createCatchupTable">
          <Form.Group className="mb-3">
            {/* <Form.Label htmlFor="title">Catchup Title</Form.Label> */}
            <Form.Control
              type="dropdown"
              placeholder="Session Title"
              autoFocus
              id="title"
              value={titleEntered}
              onChange={titleChange}
              onBlur={titleBlur}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={props.handleClose}
          type="submit"
          form="createCatchupTable"
          //disabled={!userBasicInfoFormIsValid}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FormModalTable;
