import React, { useContext, useState, useEffect } from "react";
import Axios from "axios";
import Sidebar from "../../../Components/Home/Sidebar";
import Table from "../../../Components/Department/Admin/SessionTableTanStack-admin/tanStackTable";
import CreateTableBtn from "../../../Components/Department/Admin/SessionTableTanStack-admin/Master_CreateTableBtn/CreateTableBtn";
import TableModal from "../../../Components/Department/Admin/SessionTableTanStack-admin/Master_CreateTableBtn/CreateTableModal";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { departments as Grab } from "../../../constants/index";
import { departments as GXBank } from "../../../constants/GXBank";
import { DepartmentContext } from "../../../Context/departmentContext";

import "../../../Styles/Dept/dept.scss";

export default function AdminTable() {
  //all pages needs axios credentials for session to work
  Axios.defaults.withCredentials = true;

  const { authState } = useContext(AuthContext);
  const { client } = useContext(DepartmentContext);
  //this params is from sidebar tabs component
  const { department_id } = useParams();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //map title from constants
  const listOut = [Grab, GXBank];
  const listOfDepartments = listOut[client].map((item) => item.title);

  return (
    <div>
      {authState.status === true && (
        <div className="dept d-flex">
          <Sidebar />
          <div className="deptContainer">
            <div className="dataContainer">
              {/* show modal here */}
              <TableModal
                show={show}
                handleClose={handleClose}
                department_id={department_id}
              />
              <div className="container p-3">
                <div className="d-flex justify-content-evenly m-2">
                  <div className="dataTitle py-1 text-success">
                    {listOfDepartments[department_id - 1]} catchup sessions
                  </div>
                  {/* admin can create */}
                  {authState.privilege === true && (
                    <CreateTableBtn handleShow={handleShow} />
                  )}
                </div>
                <div className="d-flex justify-content-center p-4">
                  <Table department_id={department_id} client_id={client} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
