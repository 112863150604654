import React, { useState, useContext, Fragment } from "react";
import Axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";

import { AuthContext } from "../../../../../Context/AuthContext";
import { DepartmentContext } from "../../../../../Context/departmentContext";

import { useParams } from "react-router-dom";

import {
  BiError,
  BiMessageCheck,
  /* BiMinusCircle,
  BiCheck, */
} from "react-icons/bi";
import { toast } from "react-toastify";
//import { questionTabs } from "../../../../../constants/questionTabs";

function CreateCategoryModal(props) {
  const { authState } = useContext(AuthContext);
  const { client } = useContext(DepartmentContext);

  const params = useParams();

  //[{}] default state
  //move this default to backend //relate to create input form
  //create have no relation to delete checkboxes req.body
  const [inputFields, setInputFields] = useState([
    /* openclose */
    { input: "", category_id: "opening" },
    { input: "", category_id: "closing" },
    /* questions */
    { input: "", category_id: 1 },
    { input: "", category_id: 2 },
    { input: "", category_id: 4 },
    { input: "", category_id: 5 },
    { input: "", category_id: 7 },
    { input: "", category_id: 8 },
    { input: "", category_id: 10 },
    { input: "", category_id: 12 },
    { input: "", category_id: 13 },
    { input: "", category_id: 15 },
    { input: "", category_id: 17 },
    { input: "", category_id: 18 },
    { input: "", category_id: 20 },
  ]);

  const handleInputChange = (index, event) => {
    let data = [...inputFields];
    data[index] = { category_value: event.target.value, category_id: index };
    setInputFields(data);
  };

  const submit = (e) => {
    //console.log(inputFields);
    e.preventDefault();
    Axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/checkBox/create-checkbox-group`,
      {
        client_id: client,
        table_id: params.id,
        inputFields,
        //is user_id necessary?
        user_id: authState.id,
      }
    )
      .then((response) => {
        //console.log(response);
        if (!response.status === 200) {
          throw Error("500: Resource unreachable");
        }
        //window.location.reload(false);
        //alert("Categories updated!")
        if (response.data.error) {
          return toast.error(
            <Fragment>
              <BiError /> <span>{response.data.error}</span>
            </Fragment>
          );
        } else {
          return toast.success(
            <Fragment>
              <BiMessageCheck /> <span>{response.data.success}</span>
            </Fragment>
          );
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
      });
  };

  //what is this?
  /* const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  }; */

  let disabledCreate = false;

  if (inputFields.length !== 0) {
    disabledCreate = true;
  }

  return (
    <Modal show={props.show} onHide={props.handleClose} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>
          Create new category label for each open-ended questions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* form here */}
        <Form onSubmit={submit} id="boxSubmit">
          <div className="row p-4">
            <h4 className="mb-4 text-center">
              Create categories one label at a time:
            </h4>

            <span className="d-flex flex-wrap justify-content-center bg-warning"></span>
            <div className="row justify-content-center">
              <div className="col-md-6">
                {/* try to loop with actual checkbox number later */}
                {inputFields.map((value, index) => (
                  <div key={index} className="mb-3">
                    <input
                      class="form-control text-center"
                      key={index}
                      type="text"
                      placeholder={`Enter category ${value.category_id}`}
                      value={value.category_value}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        <div>
          <Modal.Header>Confirm new categories ?</Modal.Header>

          {/* <Button onClick={addFields}>Add more...</Button> */}
          <Button
            onClick={submit}
            form="boxSubmit"
            disabled={!disabledCreate}
            //className="ms-5"
          >
            Create new category
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateCategoryModal;
