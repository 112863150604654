import { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

//chart components
import BarChart from "./ChartComponents/bar-charts/BarChartRequest";
import PieChart from "./ChartComponents/pie-charts/PieChartRequest";

import { questionTabs } from "../../../constants/questionTabs";
import { openCloseQuestion } from "../../../constants/openCloseTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          minWidth={1055}
          sx={{
            width: "100%",
            p: 2,
            //display: "flex",
            //justifyContent: "space-evenly",
            bgcolor: "white",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  //required tableID
  const params = useParams();

  // event parameter important because origin is from destructured prop function
  const [value, setValue] = useState(0);
  const changeTabHandler = (event, data) => {
    //console.log(event);
    setValue(data);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        //bgcolor: "paleturquoise",
        display: "flex",
        height: 750,
        width: "100%",
      }}
    >
      {/* tab sidebar */}
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={changeTabHandler}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 1,
          borderColor: "divider" /* , bgcolor: "turquoise" */,
        }}
      >
        {/* questions */}
        {questionTabs.map((item, index) => (
          <Tab label={item.label} {...a11yProps(index)} />
        ))}
        {/* open close tab */}
        {openCloseQuestion.map((item, index) => (
          <Tab label={item.label} {...a11yProps(index)} />
        ))}
      </Tabs>

      {/* tab content */}
      <div className="m-3">
        {/* 1 */}
        <TabPanel value={value} index={0}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[0].checkbox_question_db}
            label={questionTabs[0].label}
            colorCode={questionTabs[0].colorCode}
          />
        </TabPanel>
        {/* 2 */}
        <TabPanel value={value} index={1}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[1].checkbox_question_db}
            label={questionTabs[1].label}
            colorCode={questionTabs[1].colorCode}
          />
        </TabPanel>
        {/* 4 */}
        <TabPanel value={value} index={3}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[3].checkbox_question_db}
            label={questionTabs[3].label}
            colorCode={questionTabs[3].colorCode}
          />
        </TabPanel>
        {/* 5 */}
        <TabPanel value={value} index={4}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[4].checkbox_question_db}
            label={questionTabs[4].label}
            colorCode={questionTabs[4].colorCode}
          />
        </TabPanel>
        {/* 7 */}
        <TabPanel value={value} index={6}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[6].checkbox_question_db}
            label={questionTabs[6].label}
            colorCode={questionTabs[6].colorCode}
          />
        </TabPanel>
        {/* 8 */}
        <TabPanel value={value} index={7}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[7].checkbox_question_db}
            label={questionTabs[7].label}
            colorCode={questionTabs[7].colorCode}
          />
        </TabPanel>
        {/* 10 */}
        <TabPanel value={value} index={9}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[9].checkbox_question_db}
            label={questionTabs[9].label}
            colorCode={questionTabs[9].colorCode}
          />
        </TabPanel>
        {/* 12 */}
        <TabPanel value={value} index={11}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[11].checkbox_question_db}
            label={questionTabs[11].label}
            colorCode={questionTabs[11].colorCode}
          />
        </TabPanel>
        {/* 15 */}
        <TabPanel value={value} index={14}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[14].checkbox_question_db}
            label={questionTabs[14].label}
            colorCode={questionTabs[14].colorCode}
          />
        </TabPanel>
        {/* 17 */}
        <TabPanel value={value} index={16}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[16].checkbox_question_db}
            label={questionTabs[16].label}
            colorCode={questionTabs[16].colorCode}
          />
        </TabPanel>
        {/* 18 */}
        <TabPanel value={value} index={17}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[17].checkbox_question_db}
            label={questionTabs[17].label}
            colorCode={questionTabs[17].colorCode}
          />
        </TabPanel>
        {/* 20 */}
        <TabPanel value={value} index={19}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[19].checkbox_question_db}
            label={questionTabs[19].label}
            colorCode={questionTabs[19].colorCode}
          />
        </TabPanel>

        {/* PIECHART */}
        {/* change to 1 to 5 */}
        {/* 3 */}
        <TabPanel value={value} index={2}>
          <PieChart
            chart_id={params.id}
            question_id={questionTabs[2].category_id}
            label={questionTabs[2].label}
            colorCode={questionTabs[2].colorCode}
            options_check={questionTabs[2].options_check}
          />
        </TabPanel>
        {/* 6 */}
        <TabPanel value={value} index={5}>
          <PieChart
            chart_id={params.id}
            question_id={questionTabs[5].category_id}
            label={questionTabs[5].label}
            colorCode={questionTabs[5].colorCode}
            options_check={questionTabs[5].options_check}
          />
        </TabPanel>
        {/* 9 */}
        <TabPanel value={value} index={8}>
          <PieChart
            chart_id={params.id}
            question_id={questionTabs[8].category_id}
            label={questionTabs[8].label}
            colorCode={questionTabs[8].colorCode}
            options_check={questionTabs[8].options_check}
          />
        </TabPanel>
        {/* 14 */}
        <TabPanel value={value} index={13}>
          <PieChart
            chart_id={params.id}
            question_id={questionTabs[13].category_id}
            label={questionTabs[13].label}
            colorCode={questionTabs[13].colorCode}
            options_check={questionTabs[13].options_check}
          />
        </TabPanel>
        {/* 16 */}
        <TabPanel value={value} index={15}>
          <PieChart
            chart_id={params.id}
            question_id={questionTabs[15].category_id}
            label={questionTabs[15].label}
            colorCode={questionTabs[15].colorCode}
            options_check={questionTabs[15].options_check}
          />
        </TabPanel>

        {/* 19 */}
        <TabPanel value={value} index={18}>
          <PieChart
            chart_id={params.id}
            question_id={questionTabs[18].category_id}
            label={questionTabs[18].label}
            colorCode={questionTabs[18].colorCode}
            options_check={questionTabs[18].options_check}
          />
        </TabPanel>

        {/* ynm */}
        {/* 11 */}
        <TabPanel value={value} index={10}>
          <PieChart
            chart_id={params.id}
            question_id={questionTabs[10].category_id}
            label={questionTabs[10].label}
            colorCode={questionTabs[10].colorCode}
            options_check={questionTabs[10].options_check}
          />
        </TabPanel>
        {/* 21 */}
        <TabPanel value={value} index={20}>
          <PieChart
            chart_id={params.id}
            question_id={questionTabs[20].category_id}
            label={questionTabs[20].label}
            colorCode={questionTabs[20].colorCode}
            options_check={questionTabs[20].options_check}
          />
        </TabPanel>

        {/* options */}
        {/* 13 */}
        <TabPanel value={value} index={12}>
          <BarChart
            chart_id={params.id}
            category_id={questionTabs[12].checkbox_question_db}
            label={questionTabs[12].label}
            colorCode={questionTabs[12].colorCode}
          />
        </TabPanel>

        {/* opening & closing */}
        <TabPanel value={value} index={21}>
          <BarChart
            chart_id={params.id}
            category_id={openCloseQuestion[0].checkbox_question_db}
            label={openCloseQuestion[0].label}
            colorCode={openCloseQuestion[0].colorCode}
          />
        </TabPanel>
        <TabPanel value={value} index={22}>
          <BarChart
            chart_id={params.id}
            category_id={openCloseQuestion[1].checkbox_question_db}
            label={openCloseQuestion[1].label}
            colorCode={openCloseQuestion[1].colorCode}
          />
        </TabPanel>
        {/* end */}
      </div>
    </Box>
  );
}
