import { Bar } from "react-chartjs-2";
import /* { Chart as ChartJS } from */ "chart.js/auto";

import React from "react";

const options = {
  /*   scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  }, */
  /*  plugins: {
    legend: {
      display: false, // hide the legend to avoid overlapping with the bars
    },
  }, */
  layout: {
    padding: {
      top: 0, // add some padding to the top to avoid overlapping with the chart title
    },
  },
  barThickness: 30, // set the initial thickness of the bars
  maxBarThickness: 50, // set the maximum thickness of the bars to allow for the larger font size
};

function BarChart(props) {
  return (
    <div style={{ width: 600 }} className="mx-auto my-5">
      <h4 className="text-center">{props.label}</h4>
      <Bar data={props.chartData} options={options} />
    </div>
  );
}

export default BarChart;
