//import SimpleBackdrop from "../Components/Profile/Utils/backdrop";
import Admin from "./Tables/AdminTable";
import User from "./Tables/PlainTable";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";

export default function Departments() {
  const { authState } = useContext(AuthContext);

  /* create session or enter created session table */
  return (
    <div>
      {authState.privilege === true && <Admin />}
      {authState.privilege === "representative" && <Admin />}
      {/* non-administrative view */}
      {authState.privilege === false && <User />}
    </div>
  );
}
