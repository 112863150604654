import React, { useMemo, useState, useEffect, useContext } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTable } from "react-table";
//context
//import { DepartmentContext } from "../../../../Context/departmentContext";

function useTanStackTable(props) {
  let navigate = useNavigate();
  //const { client } = useContext(DepartmentContext);

  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/table/${props.department_id}+${props.client_id}`
    ).then((response) => {
      if (response.data.unauthorized === false) {
        //return navigate("/500");
      } else if (response.data.success) {
        //console.log(response.data.table);
        setTableList(response.data.table);
      }
    });
  }, [props]);

  /* table part */
  //spread array in useMemo
  const tableData = useMemo(() => [...tableList], [tableList]);

  //filter out unneeded headers here
  //specific render alteration
  const tableColumns = useMemo(
    () =>
      tableList[0]
        ? Object.keys(tableList[0])
            //.filter((key) => key !== "ID")
            .map((key) => {
              if (key === "Title")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => (
                    <td
                      /* key={Math.random() * 1000} */ style={{
                        width: "350px",
                      }}
                    >
                      {" "}
                      {value}
                    </td>
                  ),
                  maxWidth: 70,
                };

              return { Header: key, accessor: key };
            })
        : [],
    [tableList]
  );

  //test props
  /* useEffect(() => {
    console.log(tableList[0]);
  }, [props]); */

  //Hooks for btns
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Edit",
        Header: "Forms",
        Cell: ({ row }) => (
          <Button
            onClick={() =>
              navigate(`/department/${props.department_id}/${row.values.ID}`)
            }
          >
            Edit
          </Button>
        ),
      },
      {
        id: "Chart",
        Header: "Chart",
        Cell: ({ row }) => (
          <Button
            onClick={() => navigate(`/chart/${row.values.ID}`)}
            className="ms-2"
          >
            View
          </Button>
        ),
      },
      /* {
        id: "Settings",
        Header: "Admin",
        Cell: ({ row }) => (
          //tableID
          <Button
            onClick={() => navigate(`/admin/settings/${row.values.ID}`)}
            className="ms-2 btn-info fw-bold"
          >
            Settings
          </Button>
        ),
      }, */
      /* {
        id: "Delete",
        Header: "Drop Table",
        Cell: () => <DeleteBtn handleShow={handleShow} />,
      }, */
    ]);
  };

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: tableData,
    },
    tableHooks
    /* useGlobalFilter,
    useSortBy */
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    /* useTable({ columns, data }) */ tableInstance;

  return (
    <div>
      {tableData.length > 0 && (
        <table {...getTableProps()} style={{ border: "solid 1px blue" }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 4px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {tableData.length === 0 && (
        <div className="row d-flex justify-content-evenly mt-4">
          <div className="card border border-3 border-danger p-3">
            <h1> No session available</h1>
            <h3> Contact the Administrator to create new session</h3>
          </div>
        </div>
      )}
    </div>
  );
}

export default useTanStackTable;
