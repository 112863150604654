import React, { useContext } from "react";
import Axios from "axios";
//Session
import { useParams } from "react-router-dom";
import Sidebar from "../../../Components/Home/Sidebar";
import TanStack from "../../../Components/Department/GX-bank/CustomerSupport/Client/catchup-session/departmentSession";
import { AuthContext } from "../../../Context/AuthContext";
import { DepartmentContext } from "../../../Context/departmentContext";
import { departments as Grab } from "../../../constants/index";
import { departments as GXBank } from "../../../constants/GXBank";

import "../../../Styles/Dept/dept.scss";

const Table = () => {
  //all pages needs axios credentials for session to work
  Axios.defaults.withCredentials = true;
  const { authState } = useContext(AuthContext);
  const { client } = useContext(DepartmentContext);
  const { department_id } = useParams();

  const listOut = [Grab, GXBank];
  const listOfDepartments = listOut[client].map((item) => item.title);

  return (
    <div>
      {authState.status === true && (
        <div className="dept d-flex">
          <Sidebar />
          <div className="deptContainer">
            <div className="dataContainer">
              <div className="container p-3">
                <div className="d-flex justify-content-evenly m-2">
                  <div className="dataTitle py-1 text-success">
                    <div className="dataTitle py-1 text-success">
                      {listOfDepartments[department_id - 1]} catchup session
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center p-4">
                  <TanStack department_id={department_id} client_id={client} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
