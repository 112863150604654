import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  Fragment,
} from "react";
import Axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../../../../../Context/AuthContext";
import { questionTabs } from "../../../../../../../constants/questionTabs";

import {
  BiError,
  BiMessageCheck,
  //BiMinusCircle,
  //BiCheck,
} from "react-icons/bi";
import { toast } from "react-toastify";

function CategorizeAgentModal(props) {
  Axios.defaults.withCredentials = true;
  const { authState } = useContext(AuthContext);

  /* get request prop */
  //console.log(props.categoryState);
  const tableData = useMemo(
    () => [...props.categoryState],
    [props.categoryState]
  );
  const {
    register,
    handleSubmit,
    //watch,
    //formState: { errors },
  } = useForm();

  const CategoriesCheckBox = ({ label, id }) => {
    return (
      <div className="">
        <input
          className="me-2"
          type="checkbox"
          value={label}
          {...register("category_values")}
        />
        <Form.Label htmlFor={id}>{label}</Form.Label>
      </div>
    );
  };

  /* post request */
  const customCheckBoxHandler = (data) => {
    Axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/checkBox/categorize-response`,
      {
        user_id: authState.id,
        table_id: props.table_id,
        catchup_id: props.catchup_id,
        category_id: props.category_id,
        category_db: props.checkbox_question_db,
        ...data,
        //pending dynamic loop//maybe not because of category_id check
        //question_tabs: questionTabs,
        //question_count: questionTabs.length,
      }
    )
      .then((response) => {
        if (!response.status === 200) {
          throw Error("500: Resource unreachable");
        }
        //alert("categorized selected data")
        if (response.data.error) {
          return toast.error(
            <Fragment>
              <BiError /> <span>{response.data.error}</span>
            </Fragment>
          );
        } else {
          return toast.success(
            <Fragment>
              <BiMessageCheck /> <span>{response.data.success}</span>
            </Fragment>
          );
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
        //navigate("/403");
      });
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> Update categories </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* form here */}
        <div className="ms-4 p-1">
          {tableData.length === 0 && (
            <div className="d-flex justify-content-center">
              <div className="card border border-4 border-danger p-4">
                <h4> No categories available</h4>
              </div>
            </div>
          )}
          {tableData.length !== 0 && (
            <Form
              onSubmit={handleSubmit(customCheckBoxHandler)}
              id="boxSubmit"
              className="ms-4"
            >
              {/* INPUT MAP */}
              <Form.Group className="mb-3">
                <div className="px-3">
                  <Form.Label className="fw-bold">{props.question}</Form.Label>
                  <Form.Label className="fw-bold">
                    {props.categoryState.test}
                  </Form.Label>
                  <br />
                  {tableData.map((cat) => {
                    return (
                      cat.category_id !== "" &&
                      cat.category_id !== null && (
                        <CategoriesCheckBox
                          label={cat.category_id}
                          id={props.table_id}
                        />
                      )
                    );
                  })}
                </div>
              </Form.Group>
            </Form>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        <Modal.Header>Update categories for this Agent?</Modal.Header>
        <div>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            type="submit"
            form="boxSubmit"
            onClick={props.handleClose}
            className="ms-5"
          >
            Submit Update
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CategorizeAgentModal;
