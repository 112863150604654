//make questionTab db

//change category_id to checkbox_question_db
//barchart doesn't need category_id
export const createdCheckboxes = [
  /* open close */
  {
    label: "opening",
    actions: true,
    category_id: "opening",
    checkbox_question_db: "opening",
    colorCode: "#355E3B	",
  },
  {
    label: "closing",
    actions: true,
    category_id: "closing",
    checkbox_question_db: "closing",
    colorCode: "#355E3B",
  },
  /* questions */
  {
    label: "Question 1",
    actions: true,
    category_id: "checkbox_question_1",
    checkbox_question_db: "checkbox_question_1",
    colorCode: "#6d9eeb",
  },
  {
    label: "Question 2",
    actions: true,
    category_id: "checkbox_question_2",
    checkbox_question_db: "checkbox_question_2",
    colorCode: "#6d9eeb",
  },
  //rating 15
  {
    label: "Question 3",
    actions: false,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 4",
    actions: true,
    category_id: "checkbox_question_3",
    checkbox_question_db: "checkbox_question_3",
    colorCode: "#6d9eeb",
  },
  {
    label: "Question 5",
    actions: true,
    category_id: "checkbox_question_4",
    checkbox_question_db: "checkbox_question_4",
    colorCode: "#6d9eeb",
  },
  //rating 15
  {
    label: "Question 6",
    actions: false,
    //category_id: 8,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 7",
    actions: true,
    category_id: "checkbox_question_5",
    checkbox_question_db: "checkbox_question_5",
    colorCode: "#6d9eeb",
  },
  {
    label: "Question 8",
    actions: true,
    category_id: "checkbox_question_6",
    checkbox_question_db: "checkbox_question_6",
    colorCode: "#6d9eeb",
  },
  /* rating 15*/
  {
    label: "Question 9",
    actions: false,
    //category_id: 11,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 10",
    actions: true,
    category_id: "checkbox_question_7",
    checkbox_question_db: "checkbox_question_7",
    colorCode: "#6d9eeb",
  },
  /* ynm */
  {
    label: "Question 11",
    actions: false,
    //category_id: 13,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 1,
  },
  {
    label: "Question 12",
    actions: true,
    category_id: "checkbox_question_8",
    checkbox_question_db: "checkbox_question_8",
    colorCode: "#6d9eeb",
  },
  /* special no cb group */
  {
    label: "Question 13",
    actions: true,
    category_id: "checkbox_question_9",
    checkbox_question_db: "checkbox_question_9",
    colorCode: "#f07b72",
  },
  /* rating 1to5*/
  {
    label: "Question 14",
    actions: false,
    //category_id: 16,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 15",
    actions: true,
    category_id: "checkbox_question_10",
    checkbox_question_db: "checkbox_question_10",
    colorCode: "#6d9eeb",
  },
  /* rating 1to5*/
  {
    label: "Question 16",
    actions: false,
    //category_id: 18,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 17",
    actions: true,
    category_id: "checkbox_question_11",
    checkbox_question_db: "checkbox_question_11",
    colorCode: "#6d9eeb",
  },
  {
    label: "Question 18",
    actions: true,
    category_id: "checkbox_question_12",
    checkbox_question_db: "checkbox_question_12",
    colorCode: "#6d9eeb",
  },
  /* rating 1to5*/
  {
    label: "Question 19",
    actions: false,
    //category_id: 21,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 20",
    actions: true,
    category_id: "checkbox_question_13",
    checkbox_question_db: "checkbox_question_13",
    colorCode: "#6d9eeb",
  },
  /* ynm */
  {
    label: "Question 21",
    actions: false,
    category_id: 23,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 1,
  },
];
