import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//Toast component
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//pages
import Home from "./Pages/Home";
import DEPARTMENTS from "./Pages/Departments/Departments";
import FORMS from "./Pages/Forms/FormLayout";
import CHARTS from "./Pages/chart/GXBankChart";
import ADMINSETTINGS from "./Pages/AdminSettings";
import USERPRIVILEGES from "./Pages/UserPrivileges";
import CREATECLIENT from "./Pages/Admin/CreateNewClient";
import AUTH from "./Pages/Authentication";
import CODE500 from "./Pages/500";
import CODE404 from "./Pages/404";
import CODE403 from "./Pages/403";
import Dashboard from "./Pages/Dashboard";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* 1st */}
            <Route
              path="/department/:department_id"
              element={<DEPARTMENTS />}
            />
            {/* 2nd */}
            {
              <Route
                path="/department/:department_id/:id"
                element={<FORMS />}
              />
            }
            {/* 3rd */}
            <Route path="/chart/:id" element={<CHARTS />} />
            {/* 4th */}
            <Route path="/admin/settings/:id" element={<ADMINSETTINGS />} />

            {/* Admin */}
            <Route path="/userPrivileges" element={<USERPRIVILEGES />} />
            <Route path="/Admin/CreateNewClient" element={<CREATECLIENT />} />
            {/* Authentication */}
            <Route path="/userAuth" element={<AUTH />} />
            <Route path="/500" element={<CODE500 />} />
            <Route path="/403" element={<CODE403 />} />
            <Route path="*" element={<CODE404 />} />
          </Route>
        </Routes>
        {/* toast appears on all pages */}
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </div>
  );
}

export default App;
