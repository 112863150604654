import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import Datatable from "../responses/responsesDataTable";

/* import { questionTabs } from "../../../../../../constants/questionTabs";
import { openCloseQuestion } from "../../../../../../constants/openCloseTab"; */
import { AgentResponses } from "../../../../../../constants/agentResponsesView";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          minWidth={1350}
          sx={{
            width: "100%",
            p: 0,
            //display: "flex",
            //justifyContent: "space-evenly",
            bgcolor: "white",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  //required tableID
  const params = useParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        //bgcolor: "paleturquoise",
        display: "flex",
        height: 750,
        width: "100%",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 1,
          borderColor: "divider" /* , bgcolor: "turquoise" */,
        }}
      >
        {/* legacy tab sidebar */}
        {/* <Tab label="Q1" {...a11yProps(0)} />
        <Tab label="Q2" {...a11yProps(1)} />
        <Tab label="Q3" {...a11yProps(2)} />
        <Tab label="Q4" {...a11yProps(3)} />
        <Tab label="Q5" {...a11yProps(4)} />
        <Tab label="Q6" {...a11yProps(5)} /> */}

        {AgentResponses.map((tab, index) => (
          <Tab
            label={tab.label}
            {...a11yProps(index)}
            className="fs-7 text-capitalize"
          />
        ))}
      </Tabs>

      {/* tab content */}
      {AgentResponses.map((tab, index) => (
        <TabPanel value={value} index={index}>
          <Datatable
            table_id={params.id}
            index={index}
            actions={tab.actions}
            question={tab.label}
            category_id={tab.category_id}
            checkbox_question_db={tab.checkbox_question_db}
          />
        </TabPanel>
      ))}
    </Box>
  );
}
