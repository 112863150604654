import React, { useContext } from "react";
import Sidebar from "../Components/Home/Sidebar";
import UPTable from "../Components/AdminSettings/UserPrivilegesTable";

import { AuthContext } from "../Context/AuthContext";

import "../Styles/Dept/dept.scss";

function UserPrivileges() {
  const { authState } = useContext(AuthContext);

  return (
    <div>
      {authState.status === true && (
        <div className="dept d-flex">
          <Sidebar />
          <div className="deptContainer">
            <div className="dataContainer">
              <div className="container p-3">
                <div className="d-flex justify-content-evenly m-2">
                  <div className="dataTitle my-1 p-3 px-5 text-white bg-info rounded-5">
                    Change User privileges
                  </div>
                </div>
                <div className="d-flex justify-content-center p-4">
                  <UPTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserPrivileges;
