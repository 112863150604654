import React, { useState } from "react";
import { Button } from "react-bootstrap";

import SettingsModal from "./ChangePrivileges_modal";

function ChangePrivilegeButton(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //pass value
  const onButtonClick = (e) => {
    e.stopPropagation();
    //do whatever you want with the row
    handleShow();
    //console.log(props.params.id);
  };

  return (
    <div>
      <Button
        variant="contained"
        className="bg-success text-white"
        onClick={(e) => onButtonClick(e)}
      >
        Modify
      </Button>
      <SettingsModal
        show={show}
        handleClose={handleClose}
        userID={props.userID}
      />
    </div>
  );
}

export default ChangePrivilegeButton;
