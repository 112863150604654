//
export const openCloseQuestion = [
  {
    label: "opening",
    actions: true,
    //category_id: 1,
    checkbox_question_db: "opening",
    colorCode: "#355E3B	",
  },
  {
    label: "closing",
    actions: true,
    //category_id: 1,
    checkbox_question_db: "closing",
    colorCode: "#355E3B",
  },
];
