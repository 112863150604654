import React, { useContext } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import Sidebar from "../../../Components/Home/Sidebar";

import "../../../Styles/Dept/dept.scss";

function UserPrivileges({ children, title }) {
  const { authState } = useContext(AuthContext);

  return (
    <div>
      {authState.status === true && (
        <div className="dept d-flex">
          <Sidebar />
          <div className="deptContainer">
            <div className="dataContainer">
              <div className="container p-3">
                <div className="d-flex justify-content-evenly m-2">
                  <div className="dataTitle my-1 p-3 px-5 text-white bg-info rounded-5">
                    {title}
                  </div>
                </div>
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserPrivileges;
