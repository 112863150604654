import React, { useContext } from "react";

//Session
import { AuthContext } from "../../Context/AuthContext";

import ChartTabs from "../../Components/Department/Charts/Tabs";
import Sidebar from "../../Components/Home/Sidebar";

import "../../Styles/Dept/dept.scss";

function CEMY_Chart() {
  const { authState } = useContext(AuthContext);

  return (
    <div>
      {authState.status === true && (
        <div className="dept d-flex">
          <Sidebar />
          <div className="deptContainer">
            <div className="dataContainer">
              <div className="d-flex justify-content-evenly">
                <div className="dataTitle py-1 text-success">
                  Catchup Charts
                </div>
              </div>
              <ChartTabs />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CEMY_Chart;
