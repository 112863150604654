import React, { useState } from "react";
import { Button } from "react-bootstrap";

import CreateCategory from "./CreateCategoryModal";

function CreateCategoryBtn(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //pass value
  const onButtonClick = (e) => {
    e.stopPropagation();
    //do whatever you want with the row
    handleShow();
    //console.log(props.params.id);
    //console.log(props.tableID);
  };
  return (
    <div>
      <Button
        variant="contained"
        className="bg-success text-white"
        onClick={(e) => onButtonClick(e)}
      >
        Add Categories
      </Button>
      <CreateCategory
        show={show}
        handleClose={handleClose}
        value={props.tableID}
      />
    </div>
  );
}

export default CreateCategoryBtn;
