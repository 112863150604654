import { useState, useContext } from "react";
import Axios from "axios";
import { Button } from "react-bootstrap";
import AdminDataTable from "../../../Components/Department/GX-bank/CustomerSupport/Admin/entry/entryDataTable";
import FormModal from "../../../Components/Department/GX-bank/CustomerSupport/Admin/entry/add-Form-Modal/addEntry.Modal";
import Sidebar from "../../../Components/Home/Sidebar";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { DepartmentContext } from "../../../Context/departmentContext";

import "../../../Styles/Dept/dept.scss";

export default function AdminForm() {
  //all pages needs axios credentials for session to work
  Axios.defaults.withCredentials = true;
  const params = useParams();
  const { authState } = useContext(AuthContext);
  const { client } = useContext(DepartmentContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      {authState.status === true && (
        <div className="dept d-flex">
          <Sidebar />
          <div className="deptContainer">
            <div className="dataContainer">
              <div className="d-flex justify-content-between mx-4">
                {/* [title here] */}
                <div className="dataTitle py-2 text-success">
                  catch up entry
                </div>
                <Button className="my-1" variant="success" onClick={handleShow}>
                  Add New
                </Button>
              </div>

              <FormModal
                show={show}
                handleClose={handleClose}
                client_id={client}
                table_id={params.id}
                department_id={params.department_id}
              />

              <AdminDataTable table_id={params.id} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
