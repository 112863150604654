import React, { useState, useContext, useRef, Fragment } from "react";
import Axios from "axios";
import Nav from "react-bootstrap/Nav";

import Sidebar from "../Components/Home/Sidebar";
import SummaryStatus from "../Components/Dashboard/SummaryStatus";
import { AuthContext } from "../Context/AuthContext";

import "../Styles/Dept/dept.scss";
import "../Styles/Home/dashboard.scss";
import WeeklyAndMonthly from "../Components/Dashboard/WeeklyAndMonthly";

const Dashboard = () => {
  const { authState } = useContext(AuthContext);
  const [select, setSelect] = useState(1);

  const handleSelect = (eventKey) => {
    setSelect(eventKey);
    console.log(select);
  };
  return (
    <div>
      {authState.status === true && (
        <div className="dashboard d-flex">
          <Sidebar />
          <div className="dashboardContainer">
            <div className="summaryContainer">
              <Nav variant="tabs" defaultActiveKey="1" onSelect={handleSelect}>
                <Nav.Item>
                  <Nav.Link eventKey="1">Summary of Status</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2">Monthly and Weekly</Nav.Link>
                </Nav.Item>
              </Nav>
              {select == 2 ? <WeeklyAndMonthly /> : <SummaryStatus />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
