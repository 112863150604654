import React, { useState, useEffect, useMemo, useRef } from "react";
import Axios from "axios";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CreateCategoryBtn from "./CreateCategoryBtn/CreateCategoryBtn";
import DeleteCategoryBtn from "./DeleteSelectedCategory_Btn/DeleteSelectedCategory_btn";

//import { questionTabs } from "../../../../constants/questionTabs";
import { createdCheckboxes } from "../../../../constants/createdCheckboxesView";

function CreatedCategories_View() {
  Axios.defaults.withCredentials = true;
  const params = useParams();

  const [cats, setCats] = useState([]);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/checkBox/get-checkboxes/${params.id}`
    )
      .then((response) => {
        //console.log(response.data.data);
        setCats(response.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [params.id]);

  //how to pipe the data
  const categoryCreatedData = useMemo(() => [...cats], [cats]);
  console.log(categoryCreatedData);

  const {
    register,
    handleSubmit,
    //watch,
    //formState: { errors },
  } = useForm();

  const CheckboxInput = ({ category_id, category_label, id }) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <span>
          <input
            type="checkbox"
            value={category_label}
            id={id}
            //need to register string()
            {...register(category_id.toString())}
          />
        </span>
        {/* checbox have to be manually aligned */}
        <Form.Label htmlFor={id} className="mt-1">
          {category_label}
        </Form.Label>
      </div>
    );
  };

  return (
    <div>
      {/* CreatedCategories_View */}
      <div className="d-flex justify-content-between mx-5">
        <div className="dataTitle py-2 text-success">Created CheckBoxes</div>
        <div className="d-flex flex-lg-row flex-sm-column">
          <div className="me-3 mb-sm-2">
            <CreateCategoryBtn tableID={params.id} />
          </div>
          <div>
            <DeleteCategoryBtn
              table_id={params.id}
              deleteHandlerFunction={handleSubmit}
              categoryCreatedData={categoryCreatedData}
            />
          </div>
        </div>
      </div>
      <div>
        <Form /* onSubmit={handleSubmit(deleteCheckBoxHandler)} id="boxSubmit" */
        >
          <div className="card border-dark border-2 m-4">
            {categoryCreatedData.length === 0 && (
              <div className="d-flex justify-content-center m-5">
                <div className="card border border-4 border-danger p-4 ">
                  <h1>No categories</h1>
                  <h3> Please create categories for each session</h3>
                </div>
              </div>
            )}

            {/* mapping rows >> categoryCreatedData is useMemo from categories useState */}
            <div className="d-flex flex-wrap justify-content-start gap-5 my-4 mx-5">
              {createdCheckboxes.map((tab, index) => (
                <>
                  {categoryCreatedData.length !== 0 && tab.actions == true && (
                    <Form.Group className="mx-0 py-2 px-5 card border-dark border-1 bg-warning">
                      {/* this div below removes row effect for same sized card */}
                      <Form.Label
                        //htmlFor={`q${tab.category_id}cat`}
                        className="fw-bold"
                      >
                        {tab.label}
                      </Form.Label>

                      {categoryCreatedData[index].map((item) => {
                        return (
                          <>
                            <CheckboxInput
                              /*  */
                              category_id={tab.category_id}
                              category_label={item}
                              id={params.id}
                            />
                          </>
                        );
                      })}
                    </Form.Group>
                  )}
                </>
              ))}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default CreatedCategories_View;
