import React, { useContext, useState, useEffect } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { DepartmentContext } from "../../Context/departmentContext";
import { departments as Grab } from "../../constants";
import { departments as GXBank } from "../../constants/GXBank";

import "../../Styles/Home/tabs.scss";

const Tabs = ({ level }) => {
  let navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const { client } = useContext(DepartmentContext);
  const [expand, setExpand] = useState(false);

  //rearrange department first view array
  const listOut = [Grab, GXBank];

  //close all tab upon changing client
  useEffect(() => {
    setExpand(false);
  }, [client]);

  const list = listOut[client].filter(
    (department) =>
      department.title.charAt(0) === level && department.status === client + 1
  );

  return (
    <>
      {authState.status === true && list.length > 0 && (
        <p
          className={`${!expand ? "category" : "activeCategory"}`}
          onClick={() => setExpand(!expand)}
        >
          <span className="arrow-span">
            {level}
            {expand ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
          </span>
        </p>
      )}

      {/* this navigate still goes to top level route */}
      <div className={`${expand === false && "d-none"}`}>
        {list.map((filteredDepartment) => {
          return (
            <div>
              <li
                onClick={() => navigate(`/department/${filteredDepartment.id}`)}
              >
                <span>{filteredDepartment.title}</span>
              </li>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Tabs;
