import React from "react";
import { Button } from "react-bootstrap";

function CreateTableButton(props) {
  return (
    <Button variant="primary" onClick={props.handleShow}>
      Create Session
    </Button>
  );
}

export default CreateTableButton;
