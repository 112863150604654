import { useContext } from "react";
//import { useNavigate } from "react-router-dom";
import { DepartmentContext } from "../../Context/departmentContext";
import { clients as ClientName } from "../../constants/client";

import "../../Styles/Home/dropend.scss";

//prop is destructured as original name to accomodate children wrap
export default function Dropend({ children, onSaveClient }) {
  const { /* client, */ setClient } = useContext(DepartmentContext);

  const propUpClientHandler = (index) => {
    //const client = ["Grab", "GX Bank"];
    setClient(index);
    onSaveClient(ClientName[index].name);
  };

  return (
    <div className="btn-group dropend mainDrop">
      <span
        class="dropdown content"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {children}
      </span>
      <ul class="dropdown-menu">
        <li onClick={() => propUpClientHandler(0)}>Grab</li>
        <li onClick={() => propUpClientHandler(1)}>GX Bank</li>
      </ul>
    </div>
  );
}
