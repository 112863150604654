import { useState } from "react";

const useInput = () => {
  //true when not empty
  const isNotEmpty = (value) => value.trim() !== "";
  //true when empty
  const isNotPressed = (value) => value === "";
  //true when is an email
  const isEmail = (value) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  //true when is a phone number
  const isPhone = (value) => value.match(/^\d{10,12}$/);
  //true when is a correct password
  const isPassword = (value) =>
    value.match(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/
    );

  const [enteredValue, setEnteredValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);

  const valueIsValid = isNotEmpty(enteredValue);
  const pressedIsValid = isNotPressed(enteredValue);
  const validEmail = isEmail(enteredValue);
  const validPhone = isPhone(enteredValue);
  const validPassword = isPassword(enteredValue);
  //change this error return based on input type
  //if email change hasError to wasNotEmail
  const hasError = !valueIsValid && isTouched;
  const hasNotPressed = !pressedIsValid && isTouched;
  const wrongEmail = !validEmail && isTouched;
  const wrongPhone = !validPhone && isTouched;
  const wrongPassword = !validPassword && isTouched;

  const valueChangeHandler = (event) => {
    setEnteredValue(event.target.value);
  };

  const inputBlurHandler = () => {
    setIsTouched(true);
  };

  const reset = () => {
    setEnteredValue("");
    setIsTouched(false);
  };

  return {
    value: enteredValue,
    isValid: valueIsValid,
    hasError,
    hasNotPressed,
    wrongEmail,
    wrongPhone,
    wrongPassword,
    valueChangeHandler,
    inputBlurHandler,
    reset,
  };
};

export default useInput;
