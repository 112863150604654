import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import Axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  BiMessageCheck,
  BiMinusCircle,
  BiError,
  BiCheck,
} from "react-icons/bi";
import { toast } from "react-toastify";

//import useInput from "../../../../Hooks/useInput";
import { AuthContext } from "../../../../../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

const FormModal = (props) => {
  const { authState } = useContext(AuthContext);
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    //watch,
    //formState: { errors },
  } = useForm();

  //submit handler
  let userBasicInfoFormIsValid = true;

  const catchUpFormHandler = (data) => {
    /* table_id and department_id is from main table column */
    Axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/insert-new-entry`,
      {
        //author id
        user_id: authState.id,
        client_id: props.client_id,
        table_id: props.table_id,
        department_id: props.department_id,
        ...data,
      }
    )
      .then((response) => {
        //console.log(response);
        if (!response.status === 200) {
          navigate("/500");
          throw Error("500: Resource unreachable");
        }
        if (response.data.error) {
          //console.log(response.data);
          return toast.error(
            <Fragment>
              <BiMinusCircle /> <span>{response.data.error}</span>
            </Fragment>
          );
        } else if (response.data.warn) {
          return toast.warn(
            <Fragment>
              <BiError /> <span>{response.data.warn}</span>
            </Fragment>
          );
        } else {
          //console.log(response.data.success);
          toast.success(
            <Fragment>
              <BiMessageCheck /> <span>{response.data.success}</span>
            </Fragment>
          );
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
        navigate("/403");
      });

    //emptying fields
  };

  //const [checkbox13, setCheckbox13] = useState([]);

  /* checkbox handler */
  const CheckboxInput = ({ category_label, category_id, id }) => {
    return (
      <div className="d-flex gap-2 align-items-center">
        <span>
          <input
            type="checkbox"
            value={category_label}
            id={id}
            //need to register string()
            {...register(category_id.toString())}
          />
        </span>
        {/* checbox have to be manually aligned */}
        <Form.Label htmlFor={id} className="mt-2">
          {category_label}
        </Form.Label>
      </div>
    );
  };

  const [multipleChoice, setMultipleChoice] = useState([]);
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/checkBox/single-multiple-choice/${props.table_id}`
    )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        console.log(data.data);
        setMultipleChoice(data.data);
      })
      .catch((err) => {
        console.log(err.message);
        //navigate("/404");
      });
  }, []);

  /* const multipleChoice = [
    [
      "Spreadsheet skills",
      "Communication skills",
      "Emotional Management",
      "others",
    ],
    [],
  ]; */

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Catch-up Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(catchUpFormHandler)} id="catchupSubmit">
          <Form.Group className="mb-3">
            <Form.Label htmlFor="full_name">Full Name</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              id="full_name"
              {...register("full_name")}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="contact">Contact Number</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              id="contact"
              {...register("contact")}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="emergency_contact">
              Emergency Contact Number
            </Form.Label>
            <Form.Control
              type="text"
              autoFocus
              id="emergency_contact"
              {...register("emergency_contact")}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="work_email">Work email</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              id="work_email"
              {...register("work_email")}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="team_manager">Team Manager</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              id="team_manager"
              {...register("team_manager")}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="person_in_charge">
              Person-in-Charge (Topnotch)
            </Form.Label>
            <Form.Control
              type="text"
              autoFocus
              id="person_in_charge"
              {...register("person_in_charge")}
            />
          </Form.Group>

          <div className="fw-bold mt-5 mb-2">Opening</div>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="opening">
              How is everything going today?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="opening"
              {...register("opening")}
            />
          </Form.Group>

          <div className="fw-bold mt-5 mb-2">Operations</div>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q1">
              Q1. What has energized you in your role over a period of time?
            </Form.Label>
            <Form.Control as="textarea" rows={3} id="Q1" {...register("Q1")} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q2">
              Q2. What has challenged you over a period of time?
            </Form.Label>
            <Form.Control as="textarea" rows={3} id="Q2" {...register("Q2")} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q3">
              Q3. Do you feel confident in how you/your team are progressing?
              (Rate 1 to 5)
            </Form.Label>
            <FormControl className="d-flex align-items-center mt-2">
              <RadioGroup row name="position" defaultValue="3">
                Dislike
                <FormControlLabel
                  className="mx-2"
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  {...register("Q3")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  {...register("Q3")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  {...register("Q3")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  {...register("Q3")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  {...register("Q3")}
                />{" "}
                Very Like
              </RadioGroup>
            </FormControl>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q4">
              Q4. How do you feel about the working environment?
            </Form.Label>
            <Form.Control as="textarea" rows={3} id="Q4" {...register("Q4")} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q5">
              Q5. What two or three new skills would you like to learn on the
              job?
            </Form.Label>
            <Form.Control as="textarea" rows={3} id="Q5" {...register("Q5")} />
          </Form.Group>
          <div className="fw-bold mt-5 mb-2">Payroll</div>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q6">
              Q6. How would you rate your overall satisfaction with the current
              payroll process? (Rate 1 to 5)
            </Form.Label>
            <FormControl className="d-flex align-items-center mt-2">
              <RadioGroup row name="position" defaultValue="3">
                Dislike
                <FormControlLabel
                  className="mx-2"
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  {...register("Q6")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  {...register("Q6")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  {...register("Q6")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  {...register("Q6")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  {...register("Q6")}
                />{" "}
                Very Like
              </RadioGroup>
            </FormControl>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q7">
              Q7. Were there any challenges or discrepancies you've encountered
              in the payroll system recently?
            </Form.Label>
            <Form.Control as="textarea" rows={3} id="Q7" {...register("Q7")} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q8">
              Q8. Do you have any suggestions for improving the payroll process
              to make it more efficient?
            </Form.Label>
            <Form.Control as="textarea" rows={3} id="Q8" {...register("Q8")} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q9">
              Q9. How do you feel about the payroll part from the Onboarding
              session that you attended before? {<br />}
              (Rate 1 - hard to understand to 5- easy to understand)
            </Form.Label>
            <FormControl className="d-flex align-items-center mt-2">
              <RadioGroup row name="position" defaultValue="3">
                difficult
                <FormControlLabel
                  className="mx-2"
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  {...register("Q9")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  {...register("Q9")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  {...register("Q9")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  {...register("Q9")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  {...register("Q9")}
                />{" "}
                easy
              </RadioGroup>
            </FormControl>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q10">
              Q10. Share us your feedback on the Kakitangan system, leaves or
              any payroll related experience. Your thoughts matters to us.
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="Q10"
              {...register("Q10")}
            />
          </Form.Group>
          <div className="fw-bold mt-5 mb-2">Marketing</div>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q11">
              Q11. Do you think the email reminders (Rules & Policies Updates,
              etc.) are helpful to you?
            </Form.Label>

            <Form.Control
              as="select"
              id="Q11"
              {...register("Q11")}
              className="w-50"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Unsure">Unsure</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q12">
              Q12. As mentioned in Q1, please share suggestions for improvement
              to smoothen the communication. (if applicable)
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="Q12"
              {...register("Q12")}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3">
            <Form.Label htmlFor="Q13">
              Q13. As part of the TopCommunication skillstchCares Program, we
              are curating Self Development Tools for all FTTs. Kindly share 1
              or 2 areas that you want to improve yourself so we can create
              relevant content.
            </Form.Label>

            <Form.Control
              as="select"
              id="Q13"
              {...register("Q13")}
              className="w-50"
            >
              <option value="Spreadsheet skills">Spreadsheet skills</option>
              <option value="Communication skills">Communication skills</option>
              <option value="Emotional Management">Emotional Management</option>
              <option value="others">others</option>
            </Form.Control>
          </Form.Group> */}
          <Form.Group className="mb-3 bg-warning p-2">
            <Form.Label htmlFor="Q13">
              Q13. As part of the TopNotchCares Program, we are curating Self
              Development Tools for all FTTs. Kindly share 1 or 2 areas that you
              want to improve yourself so we can create relevant content.
            </Form.Label>

            {multipleChoice.map((item) => {
              return (
                <CheckboxInput
                  category_label={item.Q13}
                  category_id={`Q13`}
                  id={`Q13`}
                />
              );
            })}
          </Form.Group>
          {/* <Form.Group className="mb-3">
            <Form.Label htmlFor="Q13">
              Q13. As part of the TopNotchCares Program, we are curating Self
              Development Tools for all FTTs. Kindly share 1 or 2 areas that you
              want to improve yourself so we can create relevant content.
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="Q13"
              {...register("Q13")}
            />
          </Form.Group> */}
          <div className="fw-bold mt-5 mb-2">Compliance</div>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q14">
              Q14. How aligned do you feel with where the company is going?
            </Form.Label>
            <FormControl className="d-flex align-items-center mt-2">
              <RadioGroup row name="position" defaultValue="3">
                Disagree
                <FormControlLabel
                  className="mx-2"
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  {...register("Q14")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  {...register("Q14")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  {...register("Q14")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  {...register("Q14")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  {...register("Q14")}
                />{" "}
                Agree
              </RadioGroup>
            </FormControl>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q15">
              Q15. Could you explain further?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="Q15"
              {...register("Q15")}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q16">
              Q16. Do you find it comfortable to share your work challenges with
              TopNotch's PIC?
            </Form.Label>
            <FormControl className="d-flex align-items-center mt-2">
              <RadioGroup row name="position" defaultValue="3">
                Disagree
                <FormControlLabel
                  className="mx-2"
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  {...register("Q16")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  {...register("Q16")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  {...register("Q16")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  {...register("Q16")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  {...register("Q16")}
                />{" "}
                Agree
              </RadioGroup>
            </FormControl>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q17">
              {" "}
              Q17. Could you explain further?{" "}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="Q17"
              {...register("Q17")}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q18">
              Q18. How do you find the working environment in GX Bank and your
              experience thus far?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="Q18"
              {...register("Q18")}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q19">Q19. Do you feel supported?</Form.Label>
            <FormControl className="d-flex align-items-center mt-2">
              <RadioGroup row name="position" defaultValue="3">
                Disagree
                <FormControlLabel
                  className="mx-2"
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  {...register("Q19")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  {...register("Q19")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  {...register("Q19")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  {...register("Q19")}
                />
                <FormControlLabel
                  className="mx-2"
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  {...register("Q19")}
                />{" "}
                Agree
              </RadioGroup>
            </FormControl>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q20">
              Q20. Could you explain further?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="Q20"
              {...register("Q20")}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="Q21">
              Q21. Would you be interested in mental health or personal
              development programmes by TopNotch?
            </Form.Label>
            <Form.Control
              as="select"
              id="Q21"
              {...register("Q21")}
              className="w-210"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
              <option value="Maybe">Maybe</option>
            </Form.Control>
          </Form.Group>

          <div className="fw-bold mt-5 mb-2">Closing</div>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="closing">
              Is there anything we didn’t cover that you’d like to discuss next
              time?
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              id="closing"
              {...register("closing")}
            />
          </Form.Group>
          {/* endofquestions */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={props.handleClose}
          type="submit"
          form="catchupSubmit"
          disabled={!userBasicInfoFormIsValid}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FormModal;
