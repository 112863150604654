//make questionTab db

//change category_id to checkbox_question_db
//barchart doesn't need category_id
export const questionTabs = [
  {
    label: "Question 1",
    actions: true,
    category_id: 1,
    checkbox_question_db: "checkbox_question_1",
    colorCode: "#6d9eeb",
  },
  {
    label: "Question 2",
    actions: true,
    category_id: 2,
    checkbox_question_db: "checkbox_question_2",
    colorCode: "#6d9eeb",
  },
  //rating 15
  {
    label: "Question 3",
    actions: false,
    category_id: 3,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 4",
    actions: true,
    category_id: 4,
    checkbox_question_db: "checkbox_question_3",
    colorCode: "#6d9eeb",
  },
  {
    label: "Question 5",
    actions: true,
    category_id: 5,
    checkbox_question_db: "checkbox_question_4",
    colorCode: "#6d9eeb",
  },
  //rating 15
  {
    label: "Question 6",
    actions: false,
    category_id: 6,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 7",
    actions: true,
    category_id: 7,
    checkbox_question_db: "checkbox_question_5",
    colorCode: "#6d9eeb",
  },
  {
    label: "Question 8",
    actions: true,
    category_id: 8,
    checkbox_question_db: "checkbox_question_6",
    colorCode: "#6d9eeb",
  },
  /* rating 15*/
  {
    label: "Question 9",
    actions: false,
    category_id: 9,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 10",
    actions: true,
    category_id: 10,
    checkbox_question_db: "checkbox_question_7",
    colorCode: "#6d9eeb",
  },
  /* ynm */
  {
    label: "Question 11",
    actions: false,
    category_id: 11,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 1,
  },
  {
    label: "Question 12",
    actions: true,
    category_id: 12,
    checkbox_question_db: "checkbox_question_8",
    colorCode: "#6d9eeb",
  },
  /* special no cb group */
  {
    label: "Question 13",
    actions: true,
    category_id: 13,
    checkbox_question_db: "checkbox_question_9",
    colorCode: "#f07b72",
  },
  /* rating 1to5*/
  {
    label: "Question 14",
    actions: false,
    category_id: 14,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 15",
    actions: true,
    category_id: 15,
    checkbox_question_db: "checkbox_question_10",
    colorCode: "#6d9eeb",
  },
  /* rating 1to5*/
  {
    label: "Question 16",
    actions: false,
    category_id: 16,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 17",
    actions: true,
    category_id: 17,
    checkbox_question_db: "checkbox_question_11",
    colorCode: "#6d9eeb",
  },
  {
    label: "Question 18",
    actions: true,
    category_id: 18,
    checkbox_question_db: "checkbox_question_12",
    colorCode: "#6d9eeb",
  },
  /* rating 1to5*/
  {
    label: "Question 19",
    actions: false,
    category_id: 19,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 0,
  },
  {
    label: "Question 20",
    actions: true,
    category_id: 20,
    checkbox_question_db: "checkbox_question_13",
    colorCode: "#6d9eeb",
  },
  /* ynm */
  {
    label: "Question 21",
    actions: false,
    category_id: 21,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
    options_check: 1,
  },
];
/* cont */

//Rating: 3,6,9,14,16,19
//Options: 11,13,21

//total category_db //checkbox_question_db: 13, index 12

//deprecated
//CEMY
/* export const questionTabs = [
  { label: "Question 1", actions: true, category_id: 19, colorCode: "#6d9eeb" },
  {
    label: "Question 2",
    actions: false,
    category_id: 20,
    colorCode: ["#93c47d", "#f07b72", "#f1c232"],
  },
  { label: "Question 3", actions: true, category_id: 21, colorCode: "#6d9eeb" },
  { label: "Question 4", actions: true, category_id: 22, colorCode: "#6d9eeb" },
  { label: "Question 5", actions: true, category_id: 23, colorCode: "#6d9eeb" },
  { label: "Question 6", actions: true, category_id: 24, colorCode: "#6d9eeb" },
]; */
