import { useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Axios from "axios";
import CategorizeModal from "./categorizeModal";

function CategorizeAgentButton(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const params = useParams();

  //pass value
  const [cats, setCats] = useState([]);
  const onButtonClick = (e) => {
    e.stopPropagation();
    handleShow();

    //get category group handler
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/checkBox/single-checkbox-group/${params.id}+${props.checkbox_question_db}`
    )
      .then((response) => {
        console.log(response.data.data);
        setCats(response.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div>
      <Button
        variant="contained"
        className="bg-success text-white p-1"
        onClick={(e) => onButtonClick(e)}
      >
        Categorize
      </Button>
      <CategorizeModal
        show={show}
        handleClose={handleClose}
        table_id={params.id}
        catchup_id={props.params.id}
        question={props.question}
        category_id={props.category_id}
        checkbox_question_db={props.checkbox_question_db}
        categoryState={cats}
      />
    </div>
  );
}

export default CategorizeAgentButton;
