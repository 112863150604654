import React from "react";

import Sidebar from "../Components/Home/Sidebar";

import "../Styles/Home/home.scss";

function code500() {
  return (
    <div className="home d-flex">
      <Sidebar />
      <div className="homeContainer p-2">
        <h2 className="text-bg-danger text-center">500</h2>
        <h4 className="text-bg-danger text-center"> Resource is unreachable</h4>
        <h4>SERVER DOWN. Please contact the administrator.</h4>
      </div>
    </div>
  );
}

export default code500;
