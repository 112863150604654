import React, { useMemo, useState, useEffect, useContext } from "react";
import Axios from "axios";
import UserPrivilegeBtn from "../AdminSettings/settings/ChangePrivileges/ChangePrivileges_btn";
import AccountDeleteBtn from "../AdminSettings/settings/AccountDeletion/AccountDelete_Btn";

import { useNavigate } from "react-router-dom";
import { useTable } from "react-table";
import { AuthContext } from "../../Context/AuthContext";

function UserPrivilegesTable() {
  let navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    if (!authState.id) {
      return;
    }

    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/master/userPrivilegeTable/${authState.id}`,
      {
        withCredentials: true,
      }
    ).then((response) => {
      if (authState.privilege !== true) {
        console.log("Unauthorized access");
        return navigate("/403");
      } else if (response.data.success) {
        const table = response.data.accData;

        const newTable = table.map((item, index) => {
          return {
            NO: [index + 1],
            User_ID: item.user_id,
            privilege: item.admin_flag,
            name: item.name,
            email: item.email,
            phone: item.phone,
          };
        });
        setTableList(newTable);
      }
    });
  }, [authState, navigate]);

  //spread array in useMemo
  const tableData = useMemo(() => [...tableList], [tableList]);

  const tableColumns = useMemo(
    () =>
      tableList[0]
        ? Object.keys(tableList[0])
            //filter out unneeded headers
            //.filter((key) => key !== "ID")
            .map((key) => {
              //specific render alteration
              if (key === "Name")
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value }) => (
                    <td
                      /* key={Math.random() * 1000} */ style={{
                        width: "350px",
                      }}
                    >
                      {" "}
                      {value}
                    </td>
                  ),

                  maxWidth: 70,
                };
              if (key === "User_ID")
                return {
                  Header: "User ID",
                  accessor: key,
                };

              return { Header: key, accessor: key };
            })
        : [],
    [tableList]
  );

  //Hooks for btns
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "RoleChanges",
        Header: "Change Roles",
        Cell: ({ row }) => <UserPrivilegeBtn userID={row.values.User_ID} />,
      },

      {
        id: "Account Deletion",
        Header: "Delete",
        Cell: ({ row }) => <AccountDeleteBtn userID={row.values.User_ID} />,
      },
    ]);
  };

  //Table instance
  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: tableData,
    },
    tableHooks
    /* useGlobalFilter,
  useSortBy */
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    /* useTable({ columns, data }) */ tableInstance;

  return (
    <div>
      {/* tanStackTable */}
      {tableData.length > 0 && (
        <table {...getTableProps()} style={{ border: "solid 1px blue" }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 4px blue",
                      background: "deeppink",
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                      height: "40px",
                      padding: "5px",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "15px",
                          border: "solid 1px gray",
                          background: "#F0F8FF",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {/* {tableData.length === 0 && (
        <div className="row d-flex justify-content-evenly mt-4">
          <div className="card border border-3 border-danger p-3">
            <h1> No users available</h1>
            <h3> Contact the Administrator to create new privileges</h3>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default UserPrivilegesTable;
