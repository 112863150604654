import React, { useState } from "react";
import { Button } from "react-bootstrap";

import SettingsModal from "./DeleteSelectedCategory_modal";

function DeleteSelectedCategoryButton(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //pass value
  const onButtonClick = (e) => {
    //stop some kind of normal behaviour
    //e.stopPropagation();
    //do whatever you want with the row
    handleShow();
    //console.log(props.deleteHandlerFunction);
  };

  return (
    <div>
      <Button
        variant="contained"
        className="bg-danger text-white"
        onClick={(e) => onButtonClick(e)}
      >
        Delete Categories
      </Button>
      <SettingsModal
        show={show}
        handleClose={handleClose}
        value={props.table_id}
        deleteHandlerFunction={props.deleteHandlerFunction}
        categoryCreatedData={props.categoryCreatedData}
      />
    </div>
  );
}

export default DeleteSelectedCategoryButton;
