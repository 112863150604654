import Layout from "./layout/page";

export default function CreateNewClient() {
  return (
    <div>
      <Layout title="Create New Clients">
        <h1 className="text-center">content</h1>
      </Layout>
    </div>
  );
}
