//status indicate is the department needed on the quarter, 1 is on/0 is off
export const departments = [
  {
    id: 1,
    status: 1,
    title: "CEMY",
  },
  {
    id: 2,
    status: 1,
    title: "CESG",
  },
  {
    id: 3,
    status: 1,
    title: "Warehouse",
  },
  {
    id: 4,
    status: 1,
    title: "Driver Engagement",
  },
  {
    id: 5,
    status: 1,
    title: "OuterCity - Sarawak",
  },
  {
    id: 6,
    status: 1,
    title: "OuterCity - Penang",
  },
  {
    id: 7,
    status: 1,
    title: "OuterCity - Johor",
  },
  {
    id: 8,
    status: 1,
    title: "OuterCity - Melaka",
  },
  {
    id: 9,
    status: 1,
    title: "Finance (SSC)",
  },
  {
    id: 10,
    status: 1,
    title: "Market Research",
  },
  {
    id: 11,
    status: 1,
    title: "Procurement Associate",
  },
  {
    id: 12,
    status: 1,
    title: "IT",
  },
  {
    id: 13,
    status: 1,
    title: "Talent Acquisition Business Partner",
  },
  {
    id: 14,
    status: 1,
    title: "People Operation",
  },
  {
    id: 15,
    status: 1,
    title: "Grab for Business",
  },
  {
    id: 16,
    status: 1,
    title: "Project Management",
  },
  {
    id: 17,
    status: 1,
    title: "GrabFood - Operation",
  },
  {
    id: 18,
    status: 1,
    title: "GrabFood - Merchant & Acquisition",
  },
  {
    id: 19,
    status: 1,
    title: "Map Ops",
  },
  {
    id: 20,
    status: 1,
    title: "Sales Support",
  },
  {
    id: 21,
    status: 1,
    title: "Marketing",
  },
  {
    id: 22,
    status: 1,
    title: "Public Affair",
  },
  {
    id: 23,
    status: 1,
    title: "Regional Marketing Team",
  },
  {
    id: 24,
    status: 1,
    title: "GrabPay",
  },
  {
    id: 25,
    status: 1,
    title: "Facility & Procurement",
  },
  {
    id: 26,
    status: 1,
    title: "Platform Services",
  },
  {
    id: 27,
    status: 1,
    title: "Driver Acquisition",
  },
  {
    id: 28,
    status: 1,
    title: "Merchant POS Operations",
  },
  {
    id: 29,
    status: 1,
    title: "GFin - Operation",
  },
  {
    id: 30,
    status: 1,
    title: "GFin - Collection",
  },
  {
    id: 31,
    status: 1,
    title: "Platform Quality Engagement",
  },
  {
    id: 32,
    status: 1,
    title: "Corporate Security & Facilities",
  },
  {
    id: 33,
    status: 1,
    title: "Grab For Business New Platforms",
  },
  {
    id: 34,
    status: 1,
    title: "Grab Financial - Operation",
  },
  {
    id: 35,
    status: 1,
    title: "Grab Financial - Collection",
  },
];
