import React from "react";
import { Link } from "react-router-dom";

import Sidebar from "../Components/Home/Sidebar";

import "../Styles/Home/home.scss";

function code403() {
  return (
    <div className="home d-flex">
      <Sidebar />
      <div className="homeContainer p-2">
        <h2 className="text-bg-danger text-center">403</h2>
        <h4 className="text-bg-danger text-center">
          Unauthorized Access is not permitted
        </h4>
        <h4>Restricted Page! You don't have enough privilege to access this resource.</h4>
        <Link to="/">Back to landing page..</Link>
      </div>
    </div>
  );
}

export default code403;
