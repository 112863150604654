import React from "react";
import { createContext, useState } from "react";

/* defaults */
export const DepartmentContext = createContext({
  client: 0,
  setClient: () => {},
});

/* main */
export const DepartmentContextProvider = (props) => {
  /* variable exports */
  const [client, setClient] = useState(0);

  return (
    <DepartmentContext.Provider value={{ client, setClient }}>
      {props.children}
    </DepartmentContext.Provider>
  );
};

export default DepartmentContext;
