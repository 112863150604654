import React, { /* useContext, */ Fragment } from "react";
import Axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";

//import { useParams } from "react-router-dom";
//import { AuthContext } from "../../../../Hooks/AuthContext";

import {
  BiError,
  BiMessageCheck,
  /* BiMinusCircle,
  BiCheck, */
} from "react-icons/bi";
import { toast } from "react-toastify";

Axios.defaults.withCredentials = true;

function CategorizeAgentModal(props) {
  //let navigate = useNavigate();
  //const params = useParams();
  //const { authState } = useContext(AuthContext);

  const customCheckBoxHandler = (/* data */) => {
    //console.log(data);
    Axios.delete(
      `${process.env.REACT_APP_SERVER_DOMAIN}/master/accountDeletion`,
      {
        data: {
          userID: props.userID,
        },
      }
    )
      .then((response) => {
        console.log(response);
        if (!response.status === 200) {
          throw Error("500: Resource unreachable");
        }
        //window.location.reload(false);
        //alert("categorized selected data")
        if (response.data.error) {
          return toast.error(
            <Fragment>
              <BiError /> <span>{response.data.error}</span>
            </Fragment>
          );
        } else {
          return toast.success(
            <Fragment>
              <BiMessageCheck /> <span>{response.data.success}</span>
            </Fragment>
          );
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
        //navigate("/403");
      });
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Account Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* form here */}
        <div className="ms-4 p-1">
          {/* 
          {props.tableData.length === 0 && (
            <div className="d-flex justify-content-center">
              <div className="card border border-4 border-danger p-4">
                <h4> No categories available</h4>
              </div>
            </div>
          )}
          {props.tableData && (
            <div className="d-flex justify-content-center">
              <div className="card border border-4 border-danger text-bg-danger p-4">
                <h5>
                  Deleted categories will be removed from previously categorized
                  data!
                </h5>
              </div>
            </div>
          )}
        */}

          <div className="d-flex justify-content-center">
            <div className="card border border-4 border-danger text-bg-danger p-4">
              <h5>Account with this ID will be deleted</h5>
            </div>
          </div>
          <Form
            onSubmit={customCheckBoxHandler}
            id="selectedDeleteSubmit"
            className="ms-4"
          ></Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        {/* <Modal.Header>Delete selected categories ?</Modal.Header> */}
        <div>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            type="submit"
            form="selectedDeleteSubmit"
            onClick={props.handleClose}
            className="ms-5"
          >
            Confirm delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CategorizeAgentModal;
