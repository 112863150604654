import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import DeleteBtn from "./delete-btn/DeleteAgentButton";
//context
import { DepartmentContext } from "../../../../../../Context/departmentContext";

import "../../../../../../Styles/Dept/datatable.scss";

import {
  DataGrid /* GridColDef, GridValueGetterParams */,
} from "@mui/x-data-grid";

const Datatable = (props) => {
  //all pages needs axios credentials for session to work
  Axios.defaults.withCredentials = true;
  //const { authState } = useContext(AuthContext);
  const { client } = useContext(DepartmentContext);

  let navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        //params means what?
        return <DeleteBtn params={params} />;
      },
    },
    /* {
      field: "categorize",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => {
        return <CategorizeBtn params={params} />;
      },
    }, */
    { field: "id", headerName: "Entry ID", width: 50, resizable: true },
    { field: "author_name", headerName: "Author", width: 100 },
    { field: "full_name", headerName: "Full name", width: 130 },
    { field: "contact", headerName: "Contact", width: 130 },
    { field: "e_contact", headerName: "Updated Emergency Contact", width: 120 },
    { field: "r_manager", headerName: "Reporting Manager", width: 130 },
    { field: "pic_conducted", headerName: "PIC Conducted", width: 130 },
    { field: "opening", headerName: "opening", width: 120 },
    { field: "closing", headerName: "closing", width: 120 },
    { field: "Q1", headerName: "Q1", width: 100 },
    { field: "Q2", headerName: "Q2", width: 100 },
    { field: "Q3", headerName: "Q3", width: 100 },
    { field: "Q4", headerName: "Q4", width: 100 },
    { field: "Q5", headerName: "Q5", width: 100 },
    { field: "Q6", headerName: "Q6", width: 100 },
    { field: "Q7", headerName: "Q7", width: 100 },
    { field: "Q8", headerName: "Q8", width: 100 },
    { field: "Q9", headerName: "Q9", width: 100 },
    { field: "Q10", headerName: "Q10", width: 100 },
    { field: "Q11", headerName: "Q11", width: 100 },
    { field: "Q12", headerName: "Q12", width: 100 },
    { field: "Q13", headerName: "Q13", width: 400 },
    { field: "Q14", headerName: "Q14", width: 100 },
    { field: "Q15", headerName: "Q15", width: 100 },
    { field: "Q16", headerName: "Q16", width: 100 },
    { field: "Q17", headerName: "Q17", width: 100 },
    { field: "Q18", headerName: "Q18", width: 100 },
    { field: "Q19", headerName: "Q19", width: 100 },
    { field: "Q20", headerName: "Q20", width: 100 },
    { field: "Q21", headerName: "Q21", width: 100 },
  ];

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/forms-gxbank/${props.table_id}+${client}`
    )
      .then((response) => {
        console.log(response.data.data);
        return response.data;
      })
      .then((data) => {
        setRows(data.data);
      })
      .catch((err) => {
        //alert(err.message);
        navigate("/404");
      });
  }, [props.tableID, navigate]);

  return (
    <div className="datatable">
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[5]}
        // resizable={true}
        //checkboxSelection
      />
    </div>
  );
};

export default Datatable;
