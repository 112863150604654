import React, {
  useState,
  /* useEffect,
  useRef, */
  useMemo,
  useContext,
  Fragment,
} from "react";
import Axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
//import { useNavigate } from "react-router-dom";
//import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../Context/AuthContext";

import {
  BiError,
  BiMessageCheck,
  //BiMinusCircle,
  //BiCheck,
} from "react-icons/bi";
import { toast } from "react-toastify";

Axios.defaults.withCredentials = true;

function RoleChangeModal(props) {
  //let navigate = useNavigate();
  //const params = useParams();
  const { authState } = useContext(AuthContext);

  const [types, setTypes] = useState([
    "Admin",
    "Representative",
    "Regular User",
  ]);

  //DEPRECATED
  /* const effectRan = useRef(false);
  useEffect(() => {
    //console.log("useEffect");
    if (effectRan.current === true) {
      Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/cb/getCB/${params.id}`)
        .then((response) => {
          //console.log(response.data);
          for (let i = 0; i < response.data.data.length; i++) {
            setCats((prevTableList) => {
              return [
                ...prevTableList,
                {
                  cbID: response.data.data[i].cbgroupID,
                  cat1: response.data.data[i].cat1,
                  cat3: response.data.data[i].cat3,
                  cat6: response.data.data[i].cat6,
                  cat7: response.data.data[i].cat7,
                  cat8: response.data.data[i].cat8,
                  cat9: response.data.data[i].cat9,
                  cat10: response.data.data[i].cat10,
                  cat11: response.data.data[i].cat11,
                  cat13: response.data.data[i].cat13,
                  cat14: response.data.data[i].cat14,
                  cat16: response.data.data[i].cat16,
                  cat17: response.data.data[i].cat17,
                  cat18: response.data.data[i].cat18,
                },
              ];
            });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    return () => {
      //console.log("unmounted");
      effectRan.current = true;
    };
  }, [params.id]); */

  const tableData = useMemo(() => [...types], [types]);

  const {
    register,
    handleSubmit,
    //watch,
    //formState: { errors },
  } = useForm();

  const RadioBtn = ({ label, id }) => {
    return (
      <>
        <input
          className="mx-2"
          type="radio"
          value={label}
          {...register("roleChange")}
        />
        <Form.Label htmlFor={id}>{label}</Form.Label>
        <br />
      </>
    );
  };

  const customRadioHandler = (data) => {
    //console.log(data);

    Axios.post(
      `${process.env.REACT_APP_SERVER_DOMAIN}/master/userPrivilegeChange`,
      {
        userID: props.userID,
        roles: data,
        //tableID: params.id,
        //catchupID: props.value,
      }
    )
      .then((response) => {
        console.log(response);
        if (!response.status === 200) {
          throw Error("500: Resource unreachable");
        }
        //window.location.reload(false);
        //alert("categorized selected data")
        if (response.data.error) {
          return toast.error(
            <Fragment>
              <BiError /> <span>{response.data.error}</span>
            </Fragment>
          );
        } else {
          return toast.success(
            <Fragment>
              <BiMessageCheck /> <span>{response.data.success}</span>
            </Fragment>
          );
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
        //navigate("/403");
      });
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> Modify account privilege </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* form here */}
        <div className="ms-4 p-1">
          {/* {tableData.length === 0 && (
            <div className="d-flex justify-content-center">
              <div className="card border border-4 border-danger p-4">
                <h4> No categories available</h4>
              </div>
            </div>
          )} */}
          {/* {tableData.length !== 0 && (
              )} */}
          <Form
            onSubmit={handleSubmit(customRadioHandler)}
            id="boxSubmit"
            className="ms-4"
          >
            {/* 1 */}
            <Form.Group className="m-4">
              <div className="px-4">
                <Form.Label htmlFor="roleChange" className="fw-bold fs-4 mb-4">
                  Privilege Type:
                </Form.Label>
                <br />
                {tableData.map((type) => {
                  return type !== "" && <RadioBtn label={type} id={type} />;
                })}
              </div>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        <Modal.Header>Modify privilege for this account?</Modal.Header>
        <div>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            type="submit"
            form="boxSubmit"
            onClick={props.handleClose}
            className="ms-5"
          >
            Modify privilege
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default RoleChangeModal;
