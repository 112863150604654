import { useState, useEffect, useRef } from "react";
import Axios from "axios";
import Layout from "./PieChartLayout";

export default function PieChartRequest(props) {
  const effectRan = useRef(false);

  const [percentage, setPercentage] = useState([]);
  const [chartData, setchartData] = useState({
    labels: "",
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
      },
    ],
  });

  console.log(props);
  //Request
  useEffect(() => {
    if (effectRan.current === false) {
      const charts = [];
      Axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/pie-chart/${props.chart_id}+${props.question_id}+${props.options_check}`
      ).then((response) => {
        console.log(response.data);
        const chartData = response.data.chartData;
        const percentage = response.data.ratingPercentage;

        //maybe later
        //const [submission total, setPercentage] = useState([]);
        //const total = response.data.chartType.total;

        for (const key of chartData) charts.push(key);

        setchartData({
          labels: charts.map((data) => data.rating),
          datasets: [
            {
              label: props.label,
              data: charts.map((data) => data.rated),
              backgroundColor: props.colorCode,
            },
          ],
        });
        setPercentage(percentage);
      });

      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  return (
    <div>
      <Layout chartData={chartData} label={props.label} />
      {/* move this to layout */}
      <div className="container alert alert-danger">
        <h6>{props.label}</h6>
        <div className="row text-center">
          <div className="col">
            {percentage.map((data) => {
              return (
                <div>
                  <h4>{data.rating + ": " + data.percentage + "%"}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
