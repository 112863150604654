import React, { Fragment, useEffect } from "react";
//import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//Styling
import { Row, Col, Form /* Button */ } from "react-bootstrap";
import {
  BiError,
  BiMessageCheck,
  BiMinusCircle,
  BiCheck,
} from "react-icons/bi";
import { toast } from "react-toastify";

//Custom Hooks
import useMatch from "../../Hooks/use-match";
import useInput from "../../Hooks/useInput";

function Auth() {
  //SESSION
  let navigate = useNavigate();

  //Axios credentials //express-session and cookies won't work
  Axios.defaults.withCredentials = true;

  ///SIGN UP
  // Full Name Check
  const {
    value: enteredSignUpFullName,
    isValid: enteredSignUpFullNameIsValid,
    hasError: signUpFullNameInputHasError,
    valueChangeHandler: signUpFullNameChangeHandler,
    inputBlurHandler: signUpFullNameBlurHandler,
    reset: resetSignUpFullNameInput,
  } = useInput();

  // Email Input Check
  const {
    value: enteredSignUpEmail,
    isValid: enteredSignUpEmailIsValid,
    wrongEmail: signUpEmailInputHasError,
    valueChangeHandler: signUpEmailChangeHandler,
    inputBlurHandler: signUpEmailBlurHandler,
    reset: resetSignUpEmailInput,
  } = useInput();

  // Phone Input Check
  const {
    value: enteredSignUpPhone,
    isValid: enteredSignUpPhoneIsValid,
    wrongPhone: signUpPhoneInputHasError,
    valueChangeHandler: signUpPhoneChangeHandler,
    inputBlurHandler: signUpPhoneBlurHandler,
    reset: resetSignUpPhoneInput,
  } = useInput();

  // Password Input Check
  const {
    value: enteredSignUpPassword,
    isValid: enteredSignUpPasswordIsValid,
    wrongPassword: signUpPasswordInputHasError,
    valueChangeHandler: signUpPasswordChangeHandler,
    inputBlurHandler: signUpPasswordBlurHandler,
    reset: resetSignUpPasswordInput,
  } = useInput();

  ///MATCHING
  //SignUp Pwd toggle hook
  const {
    isPwdToggle: pwdToggle,
    /* togglePwd: togglePwdHandler,
      toggleHidePwd: toggleHidePwdHandler, */
  } = useMatch();
  //Match Input Check
  const {
    //values
    value: enteredMatchPassword,
    isValid: enteredMatchPasswordIsValid,
    wrongPassword: matchPasswordInputHasError,
    //handlers
    valueChangeHandler: matchPasswordChangeHandler,
    inputBlurHandler: matchPasswordBlurHandler,
    reset: resetMatchPasswordInput,
  } = useInput();
  //SignUp Match toggle hook
  const {
    isMatched: validMatch,
    isPwdToggle: pwdMatchToggle,
    onValidMatch: validMatchHandler,
    /* togglePwd: toggleMatchHandler,
      toggleHidePwd: toggleHideMatchHandler, */
  } = useMatch();
  //Login toggle hook
  const {
    isPwdToggle: pwdLogToggle,
    togglePwd: toggleOpenPwdLogHandler,
    toggleHidePwd: toggleHidePwdLogHandler,
  } = useMatch();

  //login Auth State
  //const [loginStatus, setLoginStatus] = useState("");

  ///rerender every type
  useEffect(() => {
    //returns true bool
    const match = enteredSignUpPassword === enteredMatchPassword;
    validMatchHandler(match);
  }, [enteredSignUpPassword, enteredMatchPassword, validMatchHandler]);

  useEffect(() => {}, [enteredSignUpPassword, enteredMatchPassword]);

  let signUpFormIsValid = false;
  let disabledPwd = false;

  if (!signUpPasswordInputHasError) {
    disabledPwd = true;
  }

  if (
    enteredSignUpFullName &&
    enteredSignUpEmail &&
    enteredSignUpPhone &&
    enteredSignUpPassword &&
    validMatch
  ) {
    signUpFormIsValid = true;
  }
  /* Sign Up function */
  const signUpFormSubmissionHandler = (event) => {
    event.preventDefault();
    if (
      !enteredSignUpFullNameIsValid &&
      !enteredSignUpEmailIsValid &&
      !enteredSignUpPhoneIsValid &&
      !enteredSignUpPasswordIsValid &&
      !enteredMatchPasswordIsValid
    ) {
      return;
    } else {
      Axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/signUp`, {
        name: enteredSignUpFullName,
        email: enteredSignUpEmail,
        phone: enteredSignUpPhone,
        password: enteredSignUpPassword,
      })
        .then((response) => {
          if (response.data.error) {
            return toast.error(
              <Fragment>
                <BiMinusCircle /> <span>{response.data.error}</span>
              </Fragment>
            );
          } else if (response.data.warn) {
            //email already exists
            console.log(response.data.sameEmail);
            return toast.warn(
              <Fragment>
                <BiError /> <span>{response.data.warn}</span>
              </Fragment>
            );
          } else {
            return toast.success(
              <Fragment>
                <BiMessageCheck /> <span>{response.data.success}</span>
              </Fragment>
            );
            //make separate login page
            /* if (response.data.authFlag === true) {
              navigate("/CEMY");
            } */
          }
        })
        .catch((error) => {
          alert(error.message);
          navigate("/403");
        });
    }
    resetSignUpFullNameInput();
    resetSignUpEmailInput();
    resetSignUpPhoneInput();
    resetSignUpPasswordInput();
    resetMatchPasswordInput();
  };

  const signUpFullNameClasses = signUpFullNameInputHasError
    ? "form-control form-control is-invalid"
    : "form-control form-control";

  const signUpEmailClasses = signUpEmailInputHasError
    ? "form-control form-control is-invalid"
    : "form-control form-control";

  const signUpPhoneClasses = signUpPhoneInputHasError
    ? "form-control form-control is-invalid"
    : "form-control form-control";

  const signUpPasswordClasses = signUpPasswordInputHasError
    ? "form-control form-control is-invalid"
    : "form-control form-control";

  const matchPasswordClasses = matchPasswordInputHasError
    ? "form-control form-control is-invalid"
    : "form-control form-control";

  return (
    <div>
      Create Zomin Account
      <div id="signUp" className="signUp mt-5">
        <Col sm={12} md={9} className="mx-auto">
          {/* main row */}
          <Row>
            {/* main col */}
            {/* change col height here */}
            <Col sm={12} md={6} className="min-vh-75 mx-auto">
              {/* Sign Up */}
              <div className="text-center mb-5">
                {/* <Heading content="Sign Up Now!" design="h4 mb-4" /> */}
                <h2>Create User Catch Up Account</h2>
              </div>
              <Form onSubmit={signUpFormSubmissionHandler}>
                <Col sm={12} className="mb-3">
                  <input
                    type="text"
                    id="inputSignUpFullName"
                    placeholder="Full Name"
                    className={signUpFullNameClasses}
                    onChange={signUpFullNameChangeHandler}
                    onBlur={signUpFullNameBlurHandler}
                    value={enteredSignUpFullName}
                  />
                  {signUpFullNameInputHasError && (
                    <Form.Control.Feedback type="invalid">
                      Please enter your Full Name.
                    </Form.Control.Feedback>
                  )}
                </Col>
                <Col sm={12} className="mb-3">
                  <input
                    type="email"
                    id="inputSignUpEmail"
                    placeholder="Email Address"
                    className={signUpEmailClasses}
                    onChange={signUpEmailChangeHandler}
                    onBlur={signUpEmailBlurHandler}
                    value={enteredSignUpEmail}
                  />
                  {signUpEmailInputHasError && (
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid Email Address.
                    </Form.Control.Feedback>
                  )}
                </Col>
                <Col sm={12} className="mb-3">
                  <input
                    type="text"
                    id="inputSignUpPhone"
                    placeholder="Phone Number"
                    className={signUpPhoneClasses}
                    onChange={signUpPhoneChangeHandler}
                    onBlur={signUpPhoneBlurHandler}
                    value={enteredSignUpPhone}
                  />
                  {signUpPhoneInputHasError && (
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid Phone Number.
                    </Form.Control.Feedback>
                  )}
                </Col>
                <Col sm={12} className="mb-3">
                  <div className="input-group mb-3">
                    <input
                      type={pwdToggle ? "text" : "password"}
                      id="inputSignUpPassword"
                      placeholder="Password"
                      className={signUpPasswordClasses}
                      onChange={signUpPasswordChangeHandler}
                      onBlur={signUpPasswordBlurHandler}
                      value={enteredSignUpPassword}
                    />

                    {signUpPasswordInputHasError && (
                      <Form.Control.Feedback type="invalid">
                        Must contain more than 8 characters which contain at
                        least one lowercase letter, one uppercase letter, one
                        numeric digit, and one special character.
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Col>
                {/* match input */}
                <Col sm={12} className="mb-3">
                  <div className="input-group mb-3">
                    <input
                      type={pwdMatchToggle ? "text" : "password"}
                      id="inputMatchPassword"
                      placeholder="Re-enter Password"
                      className={matchPasswordClasses}
                      onChange={matchPasswordChangeHandler}
                      onBlur={matchPasswordBlurHandler}
                      value={enteredMatchPassword}
                      disabled={!disabledPwd}
                    />

                    {matchPasswordInputHasError && (
                      <Form.Control.Feedback type="invalid">
                        Value entered must match the previous password.
                      </Form.Control.Feedback>
                    )}
                  </div>
                  {validMatch && (
                    <label>
                      Password Matched:
                      <BiCheck />
                    </label>
                  )}
                </Col>

                <Col className="mb-3">
                  <label>
                    By clicking sign up, you agree to Top Notch's{" "}
                    <a className="text-decoration-none" href="/PrivacyPolicy">
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a className="text-decoration-none" href="/TermOfUse">
                      Term of Use
                    </a>
                  </label>
                </Col>
                <Col sm={12} className="mb-3 text-center mx-auto">
                  <button
                    type="submit"
                    className=" btn btn-success"
                    /* should I remove this in favor of session? */
                    disabled={!signUpFormIsValid}
                  >
                    Create Account
                  </button>
                </Col>
              </Form>
            </Col>
            {/* main col */}
          </Row>
          {/* main row */}
        </Col>
      </div>
    </div>
  );
}

export default Auth;
