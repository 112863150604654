import React from "react";
import { useParams } from "react-router-dom";

import DeleteTableBtn from "./Master_TableDeleteBtn/DeleteTableButton";

function MasterTable_Settings() {
  const params = useParams();
  return (
    <div>
      {/* MasterTable_Settings */}
      <div className="d-flex justify-content-between mx-4">
        <div className="dataTitle py-2 text-success">Master Settings</div>
        <div>
          <DeleteTableBtn tableID={params.id} />
        </div>
      </div>
    </div>
  );
}

export default MasterTable_Settings;
