import { useContext, Fragment } from "react";
import Axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  BiError,
  BiMessageCheck,
  //BiMinusCircle,
  //BiCheck,
} from "react-icons/bi";
import { toast } from "react-toastify";

function DeleteTablePrompt(props) {
  let navigate = useNavigate();

  const deleteTable = () => {
    Axios.delete(
      `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/delete-entry/${props.value}`
    )
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          return toast.error(
            <Fragment>
              <BiError /> <span>{response.data.error}</span>
            </Fragment>
          );
        } else {
          return toast.success(
            <Fragment>
              <BiMessageCheck /> <span>{response.data.success}</span>
            </Fragment>
          );
        }
      })
      .catch(() => {
        navigate("/403");
      });
    //console.log(props.value);
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> Delete Agent Data! </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title>Delete this agent data?</Modal.Title>
        <h1>Entry ID: {props.value}</h1>
        <div className="d-flex justify-content-evenly mt-4">
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={props.handleClose && deleteTable}>
            Confirm Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteTablePrompt;
