import { Fragment, useState, useEffect, useContext } from "react";
//import { Helmet, HelmetProvider } from "react-helmet-async";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

//Styling
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  BiError,
  BiMessageCheck,
  BiMinusCircle,
  BiCheck,
} from "react-icons/bi";
import { AiOutlineEyeInvisible, AiFillEye } from "react-icons/ai";
import { toast } from "react-toastify";

//Session
import { AuthContext } from "../../Context/AuthContext";
//Custom Hooks
import useMatch from "../../Hooks/use-match";
import useInput from "../../Hooks/useInput";

function Auth() {
  /// investigate this

  //SESSION
  let navigate = useNavigate();
  const { authState, setAuthState } = useContext(AuthContext);

  //Toggle Sign up and Log In form
  const [userIsRegistered, setLogin] = useState(false);
  function toggleLogin() {
    setLogin(!userIsRegistered);
  }

  ///SIGN UP
  // Full Name Check
  const {
    value: enteredSignUpFullName,
    isValid: enteredSignUpFullNameIsValid,
    hasError: signUpFullNameInputHasError,
    valueChangeHandler: signUpFullNameChangeHandler,
    inputBlurHandler: signUpFullNameBlurHandler,
    reset: resetSignUpFullNameInput,
  } = useInput();

  // Email Input Check
  const {
    value: enteredSignUpEmail,
    isValid: enteredSignUpEmailIsValid,
    wrongEmail: signUpEmailInputHasError,
    valueChangeHandler: signUpEmailChangeHandler,
    inputBlurHandler: signUpEmailBlurHandler,
    reset: resetSignUpEmailInput,
  } = useInput();

  // Phone Input Check
  const {
    value: enteredSignUpPhone,
    isValid: enteredSignUpPhoneIsValid,
    wrongPhone: signUpPhoneInputHasError,
    valueChangeHandler: signUpPhoneChangeHandler,
    inputBlurHandler: signUpPhoneBlurHandler,
    reset: resetSignUpPhoneInput,
  } = useInput();

  // Password Input Check
  const {
    value: enteredSignUpPassword,
    isValid: enteredSignUpPasswordIsValid,
    wrongPassword: signUpPasswordInputHasError,
    valueChangeHandler: signUpPasswordChangeHandler,
    inputBlurHandler: signUpPasswordBlurHandler,
    reset: resetSignUpPasswordInput,
  } = useInput();

  ///LOGIN
  // Email Input Check
  const {
    value: enteredLogEmail,
    isValid: enteredLogEmailIsValid,
    wrongEmail: logEmailInputHasError,
    valueChangeHandler: logEmailChangeHandler,
    inputBlurHandler: logEmailBlurHandler,
    reset: resetLogEmailInput,
  } = useInput();

  // Password Input Check
  const {
    value: enteredLogPassword,
    isValid: enteredLogPasswordIsValid,
    wrongPassword: logPasswordInputHasError,
    valueChangeHandler: logPasswordChangeHandler,
    inputBlurHandler: logPasswordBlurHandler,
    reset: resetLogPasswordInput,
  } = useInput();

  ///MATCHING
  //SignUp Pwd toggle hook
  const {
    isPwdToggle: pwdToggle,
    /* togglePwd: togglePwdHandler,
    toggleHidePwd: toggleHidePwdHandler, */
  } = useMatch();
  //Match Input Check
  const {
    //values
    value: enteredMatchPassword,
    isValid: enteredMatchPasswordIsValid,
    wrongPassword: matchPasswordInputHasError,
    //handlers
    valueChangeHandler: matchPasswordChangeHandler,
    inputBlurHandler: matchPasswordBlurHandler,
    reset: resetMatchPasswordInput,
  } = useInput();
  //SignUp Match toggle hook
  const {
    isMatched: validMatch,
    isPwdToggle: pwdMatchToggle,
    onValidMatch: validMatchHandler,
    /* togglePwd: toggleMatchHandler,
    toggleHidePwd: toggleHideMatchHandler, */
  } = useMatch();
  //Login toggle hook
  const {
    isPwdToggle: pwdLogToggle,
    togglePwd: toggleOpenPwdLogHandler,
    toggleHidePwd: toggleHidePwdLogHandler,
  } = useMatch();

  //login Auth State
  //const [loginStatus, setLoginStatus] = useState("");

  ///rerender every type
  useEffect(() => {
    //returns true bool
    const match = enteredSignUpPassword === enteredMatchPassword;
    validMatchHandler(match);
  }, [enteredSignUpPassword, enteredMatchPassword, validMatchHandler]);

  useEffect(() => {}, [enteredSignUpPassword, enteredMatchPassword]);

  let signUpFormIsValid = false;
  let disabledPwd = false;

  if (!signUpPasswordInputHasError) {
    disabledPwd = true;
  }

  if (
    enteredSignUpFullName &&
    enteredSignUpEmail &&
    enteredSignUpPhone &&
    enteredSignUpPassword &&
    validMatch
  ) {
    signUpFormIsValid = true;
  }
  /* Sign Up function */
  const signUpFormSubmissionHandler = (event) => {
    event.preventDefault();
    if (
      !enteredSignUpFullNameIsValid &&
      !enteredSignUpEmailIsValid &&
      !enteredSignUpPhoneIsValid &&
      !enteredSignUpPasswordIsValid &&
      !enteredMatchPasswordIsValid
    ) {
      return;
    } else {
      Axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/signUp`, {
        name: enteredSignUpFullName,
        email: enteredSignUpEmail,
        phone: enteredSignUpPhone,
        password: enteredSignUpPassword,
      })
        .then((response) => {
          if (response.data.error) {
            return toast.error(
              <Fragment>
                <BiMinusCircle /> <span>{response.data.error}</span>
              </Fragment>
            );
          } else if (response.data.warn) {
            //session test
            //console.log(response.data.inputData);
            //email already exists
            console.log(response.data.sameEmail);
            return toast.warn(
              <Fragment>
                <BiError /> <span>{response.data.warn}</span>
              </Fragment>
            );
          } else {
            return toast.success(
              <Fragment>
                <BiMessageCheck /> <span>{response.data.success}</span>
              </Fragment>
            );
            //make separate login page
            /* if (response.data.authFlag === true) {
            navigate("/CEMY");
          } */
          }
        })
        .catch((error) => {
          alert(error.message);
          navigate("/403");
        });
    }
    resetSignUpFullNameInput();
    resetSignUpEmailInput();
    resetSignUpPhoneInput();
    resetSignUpPasswordInput();
    resetMatchPasswordInput();
  };

  let loginFormIsValid = false;

  if (enteredLogEmailIsValid && enteredLogPasswordIsValid) {
    loginFormIsValid = true;
  }
  /* Sign In function */
  const logInFormSubmissionHandler = (event) => {
    event.preventDefault();

    if (!enteredLogEmailIsValid && !enteredLogPasswordIsValid) {
      return;
    } else {
      Axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/signIn`, {
        email: enteredLogEmail,
        password: enteredLogPassword,
      })
        .then((response) => {
          if (response.data.error) {
            return toast.error(
              <Fragment>
                <BiMinusCircle /> <span>{response.data.error}</span>
              </Fragment>
            );
          } else if (response.data.warn) {
            return toast.warn(
              <Fragment>
                <BiError /> <span>{response.data.warn}</span>
              </Fragment>
            );
          } else {
            toast.success(
              <Fragment>
                <BiMessageCheck /> <span>{response.data.success}</span>
              </Fragment>
            );
            setAuthState({
              id: response.data.id,
              status: response.data.authFlag,
              privilege: response.data.privilege,
            });
            ///applying tokens later...
            //storing tokens or session in localStorage
            /* localStorage.setItem("accessToken", response.data.token);
          setAuthState({
            id: response.data.id,
            admin: response.data.admin,
            company: response.data.company,
            attempt: response.data.attempt,
            status: true,
          });*/
            if (response.data.privilege === true) {
              navigate("/dashboard");
            } else {
              navigate("/");
            }
          }
        })
        .catch((error) => {
          console.log(error);
          navigate("/403");
        });
    }
    resetLogEmailInput();
    resetLogPasswordInput();
  };
  //console.log(authState);

  ///Input classNames
  const logEmailClasses = logEmailInputHasError
    ? "form-control form-control-lg is-invalid"
    : "form-control form-control-lg";

  const logPasswordClasses = logPasswordInputHasError
    ? "form-control form-control-lg is-invalid"
    : "form-control form-control-lg";

  const signUpFullNameClasses = signUpFullNameInputHasError
    ? "form-control form-control is-invalid"
    : "form-control form-control";

  const signUpEmailClasses = signUpEmailInputHasError
    ? "form-control form-control is-invalid"
    : "form-control form-control";

  const signUpPhoneClasses = signUpPhoneInputHasError
    ? "form-control form-control is-invalid"
    : "form-control form-control";

  const signUpPasswordClasses = signUpPasswordInputHasError
    ? "form-control form-control is-invalid"
    : "form-control form-control";

  const matchPasswordClasses = matchPasswordInputHasError
    ? "form-control form-control is-invalid"
    : "form-control form-control";

  return (
    <div>
      Authentication
      <div id="signUp" className="signUp mt-5">
        <Col sm={12} md={9} className="mx-auto">
          {/* main row */}
          <Row>
            {/* main col */}
            {/* change col height here */}
            <Col sm={12} md={6} className="min-vh-75 mx-auto">
              {userIsRegistered ? (
                /* Sign Up */
                <>
                  <div className="text-center mb-5">
                    {/* <Heading content="Sign Up Now!" design="h4 mb-4" /> */}
                    <h1>Sign Up</h1>
                  </div>
                  <Form onSubmit={signUpFormSubmissionHandler}>
                    <Col sm={12} className="mb-3">
                      <input
                        type="text"
                        id="inputSignUpFullName"
                        placeholder="Full Name"
                        className={signUpFullNameClasses}
                        onChange={signUpFullNameChangeHandler}
                        onBlur={signUpFullNameBlurHandler}
                        value={enteredSignUpFullName}
                      />
                      {signUpFullNameInputHasError && (
                        <Form.Control.Feedback type="invalid">
                          Please enter your Full Name.
                        </Form.Control.Feedback>
                      )}
                    </Col>
                    <Col sm={12} className="mb-3">
                      <input
                        type="email"
                        id="inputSignUpEmail"
                        placeholder="Email Address"
                        className={signUpEmailClasses}
                        onChange={signUpEmailChangeHandler}
                        onBlur={signUpEmailBlurHandler}
                        value={enteredSignUpEmail}
                      />
                      {signUpEmailInputHasError && (
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid Email Address.
                        </Form.Control.Feedback>
                      )}
                    </Col>
                    <Col sm={12} className="mb-3">
                      <input
                        type="text"
                        id="inputSignUpPhone"
                        placeholder="Phone Number"
                        className={signUpPhoneClasses}
                        onChange={signUpPhoneChangeHandler}
                        onBlur={signUpPhoneBlurHandler}
                        value={enteredSignUpPhone}
                      />
                      {signUpPhoneInputHasError && (
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid Phone Number.
                        </Form.Control.Feedback>
                      )}
                    </Col>
                    <Col sm={12} className="mb-3">
                      <div className="input-group mb-3">
                        <input
                          type={pwdToggle ? "text" : "password"}
                          id="inputSignUpPassword"
                          placeholder="Password"
                          className={signUpPasswordClasses}
                          onChange={signUpPasswordChangeHandler}
                          onBlur={signUpPasswordBlurHandler}
                          value={enteredSignUpPassword}
                        />
                        {/* <div
                          onClick={() => {
                            pwdToggle
                              ? toggleHidePwdHandler()
                              : togglePwdHandler();
                          }}
                          className="input-group-text"
                        >
                          {pwdToggle ? (
                            <AiFillEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </div> */}
                        {signUpPasswordInputHasError && (
                          <Form.Control.Feedback type="invalid">
                            Must contain more than 8 characters which contain at
                            least one lowercase letter, one uppercase letter,
                            one numeric digit, and one special character.
                          </Form.Control.Feedback>
                        )}
                      </div>
                    </Col>
                    {/* match input */}
                    <Col sm={12} className="mb-3">
                      <div className="input-group mb-3">
                        <input
                          type={pwdMatchToggle ? "text" : "password"}
                          id="inputMatchPassword"
                          placeholder="Re-enter Password"
                          className={matchPasswordClasses}
                          onChange={matchPasswordChangeHandler}
                          onBlur={matchPasswordBlurHandler}
                          value={enteredMatchPassword}
                          disabled={!disabledPwd}
                        />
                        {/* <div
                          onClick={() => {
                            pwdMatchToggle
                              ? toggleHideMatchHandler()
                              : toggleMatchHandler();
                          }}
                          className="input-group-text"
                        >
                          {pwdMatchToggle ? (
                            <AiFillEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </div> */}
                        {matchPasswordInputHasError && (
                          <Form.Control.Feedback type="invalid">
                            Value entered must match the previous password.
                          </Form.Control.Feedback>
                        )}
                      </div>
                      {validMatch && (
                        <label>
                          Password Matched:
                          <BiCheck />
                        </label>
                      )}
                    </Col>

                    <Col className="mb-3">
                      <label>
                        By clicking sign up, you agree to Top Notch's{" "}
                        <a
                          className="text-decoration-none"
                          href="/PrivacyPolicy"
                        >
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a className="text-decoration-none" href="/TermOfUse">
                          Term of Use
                        </a>
                      </label>
                    </Col>
                    <Col sm={12} className="mb-3 text-center mx-auto">
                      <button
                        type="submit"
                        className=" btn btn-success"
                        /* should I remove this in favor of session? */
                        disabled={!signUpFormIsValid}
                      >
                        Sign Up
                      </button>
                    </Col>
                    <Row>
                      <Col className=" text-end">
                        <Button variant="link" onClick={toggleLogin}>
                          Already have an account?
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              ) : (
                /* Sign In */
                <>
                  <div className="text-center mb-5">
                    {/* <Heading content="Log In Now!" design="h4 mb-4" /> */}
                    <h1>Log In</h1>
                  </div>

                  <div className="container text-center">
                    <Form onSubmit={logInFormSubmissionHandler}>
                      <Col className="mb-3">
                        <input
                          type="email"
                          id="inputLogEmail"
                          placeholder="Email Address"
                          className={logEmailClasses}
                          /* size={50} */
                          onChange={logEmailChangeHandler}
                          onBlur={logEmailBlurHandler}
                          value={enteredLogEmail}
                        />
                        {logEmailInputHasError && (
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid Email Address.
                          </Form.Control.Feedback>
                        )}
                      </Col>

                      <Col className="mb-3 ">
                        <div className="input-group mb-3">
                          <input
                            type={pwdLogToggle ? "text" : "password"}
                            id="inputLogPassword"
                            placeholder="Password"
                            className={logPasswordClasses}
                            onChange={logPasswordChangeHandler}
                            onBlur={logPasswordBlurHandler}
                            value={enteredLogPassword}
                          />
                          <div
                            onClick={() => {
                              pwdLogToggle
                                ? toggleHidePwdLogHandler()
                                : toggleOpenPwdLogHandler();
                            }}
                            className="input-group-text"
                          >
                            {pwdLogToggle ? (
                              <AiFillEye />
                            ) : (
                              <AiOutlineEyeInvisible />
                            )}
                          </div>
                          {logPasswordInputHasError && (
                            <Form.Control.Feedback type="invalid">
                              Must contain more than 8 characters which contain
                              at least one lowercase letter, one uppercase
                              letter, one numeric digit, and one special
                              character.
                            </Form.Control.Feedback>
                          )}
                        </div>
                      </Col>

                      <Col sm={12} className="my-4 text-center mx-auto">
                        <button
                          type="submit"
                          className=" btn btn-primary"
                          disabled={!loginFormIsValid}
                        >
                          Log In
                        </button>
                      </Col>
                    </Form>
                    <Row>
                      <Col className="text-start">
                        {/* <Button variant="link" href="/forgetpassword">
                          {!userIsRegistered && "Forget Password?"}
                        </Button> */}
                      </Col>
                      {authState.privilege === true && (
                        <Col className=" text-end">
                          <Button variant="link" onClick={toggleLogin}>
                            Don't have an account?
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </div>
                </>
              )}
            </Col>
            {/* main col */}
          </Row>
          {/* main row */}
        </Col>
      </div>
    </div>
  );
}

export default Auth;
