import React, { useContext } from "react";
import Sidebar from "../Components/Home/Sidebar";
import SignUp from "../Components/Authentication/SignUp";
import SignIn from "../Components/Authentication/Auth";

import { AuthContext } from "../Context/AuthContext";

import "../Styles/Home/home.scss";

function Authentication() {
  const { authState } = useContext(AuthContext);

  return (
    <div className="home d-flex">
      <Sidebar />
      <div className="homeContainer">
        <div>Auth Page</div>

        {authState.status === true ? <SignUp /> : <SignIn />}
      </div>
    </div>
  );
}

export default Authentication;
