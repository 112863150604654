import React, { useState } from "react";
import { Button } from "react-bootstrap";

import DeleteModal from "./DeleteAgentPrompt";

function DeleteTableButton(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //Delete function
  const onButtonClick = (e) => {
    e.stopPropagation();
    //do whatever you want with the row
    handleShow();
    //console.log(props.params.id);
  };

  return (
    <div>
      <Button
        variant="contained"
        className="bg-danger text-white"
        onClick={(e) => onButtonClick(e)}
      >
        Delete
      </Button>
      <DeleteModal
        show={show}
        handleClose={handleClose}
        value={props.params.id}
      />
    </div>
  );
}

export default DeleteTableButton;
