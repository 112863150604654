import React from "react";

import AdminTabs from "../../src/Components/Department/Admin/Settings/AdminSettings_Tabs";
import Sidebar from "../../src/Components/Home/Sidebar";

function AdminSettings() {
  return (
    <div>
      {/* AdminSettings */}
      <div className="dept d-flex">
        <Sidebar />
        <div className="deptContainer">
          <div className="dataContainer">
            <div className="d-flex justify-content-center">
              <div className="dataTitle py-1 text-success">
                Catch-up Dashboard
              </div>
            </div>
            <AdminTabs />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSettings;
