import React, { useState, useContext, useRef, Fragment } from "react";
import Axios from "axios";

import { DataSheetGrid, textColumn, keyColumn } from "react-datasheet-grid";
import "react-datasheet-grid/dist/style.css";
import { BiMessageCheck } from "react-icons/bi";

import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

const SummaryStatus = () => {
  const effectRan = useRef(false);

  const refreshPage = () => {
    window.location.reload(false);
  };

  const [data, setData] = useState([]);
  const columns = [
    {
      ...keyColumn("department", textColumn),
      title: "Department",
      disabled: true,
    },
    {
      ...keyColumn("start_date", textColumn),
      title: "Start Date",
    },
    {
      ...keyColumn("active_agent", textColumn),
      title: "Active Agent (Overall)",
    },
    {
      ...keyColumn("month_of_joining_start", textColumn),
      title: "Month of Joining (Start)",
    },
    {
      ...keyColumn("month_of_joining_end", textColumn),
      title: "Month of Joining (End)",
    },
    {
      ...keyColumn("total_agent", textColumn),
      title: "Total Agent",
    },
    {
      ...keyColumn("completed_agent", textColumn),
      title: "Completed Agents",
    },
    {
      ...keyColumn("not_completed_agent", textColumn),
      title: "Not Completed Agent/Resigners",
    },
    {
      ...keyColumn("completed_date", textColumn),
      title: "Completed Date",
    },
  ];
  useEffect(() => {
    //console.log("effect ran");
    if (effectRan.current === false) {
      Axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/CEMY/dashboard/summary`
      ).then((response) => {
        const summary = response.data.data[0][0];
        const agent_data = response.data.data[0][1];
        for (let i = 0; i < agent_data.length; i++) {
          summary.map((department) => {
            if (
              department.idcatchup_summary_status ===
              agent_data[i].department_id
            ) {
              department.completed_agent = agent_data[i].number;
              console.log(department.completed_agent);
            }
          });
        }
        for (let i = 0; i < summary.length; i++) {
          setData((prevData) => {
            return [
              ...prevData,
              {
                id: summary[i].idcatchup_summary_status,
                department: summary[i].department_name,
                start_date: summary[i].start_date,
                active_agent: summary[i].active_agent,
                month_of_joining_start: summary[i].month_of_joining_start,
                month_of_joining_end: summary[i].month_of_joining_end,
                total_agent: summary[i].total_agent,
                completed_agent: summary[i].completed_agent,
                not_completed_agent:
                  summary[i].total_agent - summary[i].completed_agent,
                completed_date: summary[i].completed_date,
              },
            ];
          });
        }
      });

      return () => {
        //console.log("unmounted");
        effectRan.current = true;
      };
    }
  }, [data]);
  const saveData = () => {
    Axios.patch(
      `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/CEMY/dashboard/summary`,
      {
        data: data,
      }
    ).then((response) => {
      return toast.success(
        <Fragment>
          <BiMessageCheck /> <span>{response.data.success}</span>
        </Fragment>
      );
    });
    refreshPage();
  };

  return (
    <div>
      <Button className="mx-2 my-1" onClick={saveData}>
        Save
      </Button>
      <DataSheetGrid value={data} onChange={setData} columns={columns} />
    </div>
  );
};

export default SummaryStatus;
