import { useContext } from "react";
import Sidebar from "../Components/Home/Sidebar";
import Dashboard from "./Dashboard";
import { AuthContext } from "../Context/AuthContext";

import "../Styles/Home/home.scss";

const Home = () => {
  const { authState } = useContext(AuthContext);

  return (
    <>
      {authState.status === true ? (
        <>
          <Dashboard />
        </>
      ) : (
        <div className="home d-flex">
          <Sidebar />
          <div className="homeContainer p-2">
            Home container
            <h4>Landing Page</h4>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
