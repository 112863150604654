import React, { useState, useRef, Fragment } from "react";
import Axios from "axios";

import {
  DataSheetGrid,
  textColumn,
  keyColumn,
  intColumn,
} from "react-datasheet-grid";
import "react-datasheet-grid/dist/style.css";

import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

const WeeklyAndMonthly = () => {
  const effectRan = useRef(false);

  const [data, setData] = useState([
    { id: 1, department: "CEMY" },
    { id: 2, department: "CESG" },
    { id: 3, department: "Warehouse" },
    { id: 4, department: "Driver Engagement" },
    { id: 5, department: "Sarawak" },
    { id: 6, department: "Penang" },
    { id: 7, department: "Johor" },
    { id: 8, department: "Melaka" },
    { id: 9, department: "Finance (SSC)" },
    { id: 10, department: "Market Research" },
    { id: 11, department: "Procurement Associate" },
    { id: 12, department: "IT" },
    { id: 13, department: "Talent Acquisition Business Partner" },
    { id: 14, department: "People Operation" },
    { id: 15, department: "Grab for Business" },
    { id: 16, department: "Project Management" },
    { id: 17, department: "GrabFood - Operations" },
    { id: 18, department: "GrabFood - Merchant & Acquisition" },
    { id: 19, department: "Map Ops" },
    { id: 20, department: "Sales Support" },
    { id: 21, department: "Marketing" },
    { id: 22, department: "Public Affair" },
    { id: 23, department: "Regional Marketing Team" },
    { id: 24, department: "GrabPay" },
    { id: 25, department: "Facility & Procurement" },
    { id: 26, department: "Platform Services" },
    { id: 27, department: "Driver Acquisition" },
    { id: 28, department: "Merchant POS Operations" },
    { id: 29, department: "GFin - Operation" },
    { id: 30, department: "GFin - Collection" },
  ]);
  const columns = [
    {
      ...keyColumn("department", textColumn),
      title: "Department",
      disabled: true,
    },
    {
      ...keyColumn("sep_1", textColumn),
      title: "Jan 1",
    },
    {
      ...keyColumn("sep_2", textColumn),
      title: "Jan 2",
    },
    {
      ...keyColumn("sep_3", textColumn),
      title: "Jan 3",
    },
    {
      ...keyColumn("sep_4", textColumn),
      title: "Jan 4",
    },
    {
      ...keyColumn("sep_5", textColumn),
      title: "Jan 5",
    },
    {
      ...keyColumn("sep_total", textColumn),
      title: "Jan Total",
    },
    {
      ...keyColumn("oct_1", textColumn),
      title: "Feb 1",
    },
    {
      ...keyColumn("oct_2", textColumn),
      title: "Feb 2",
    },
    {
      ...keyColumn("oct_3", textColumn),
      title: "Feb 3",
    },
    {
      ...keyColumn("oct_4", textColumn),
      title: "Feb 4",
    },
    {
      ...keyColumn("oct_5", textColumn),
      title: "Feb 5",
    },
    {
      ...keyColumn("oct_total", textColumn),
      title: "Feb Total",
    },
    {
      ...keyColumn("nov_1", textColumn),
      title: "Mar 1",
    },
    {
      ...keyColumn("nov_2", textColumn),
      title: "Mar 2",
    },
    {
      ...keyColumn("nov_3", textColumn),
      title: "Mar 3",
    },
    {
      ...keyColumn("nov_4", textColumn),
      title: "Mar 4",
    },
    {
      ...keyColumn("nov_5", textColumn),
      title: "Mar 5",
    },
    {
      ...keyColumn("nov_total", textColumn),
      title: "Mar Total",
    },
  ];

  // useState(()=>{
  //   const newState = data.map(department=>{
  //     if(department.id ===5){
  //       return {...department, sep_total:20,sep_5:25};
  //     }
  //     return department;
  //   })
  //   setData(newState)
  // })

  useEffect(() => {
    //console.log("effect ran");
    if (effectRan.current === false) {
      Axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/catchup/CEMY/dashboard/agentNumber`
      ).then((response) => {
        const fetchData = response.data.data[0];
        // for(let i =0; i<data.length; i++){
        //   console.log(response.data.data[0][i][0])
        // }
        let newData = data;
        //console.log(fetchData)
        // first month_1
        for (let i = 0; i < fetchData[0].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[0][i].department_id) {
              return { ...department, sep_1: fetchData[0][i].sep_1 };
            }
            return department;
          });
          newData = newState;
        }
        //first month_2
        for (let i = 0; i < fetchData[1].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[1][i].department_id) {
              return { ...department, sep_2: fetchData[1][i].sep_2 };
            }
            return department;
          });
          newData = newState;
        }
        //first month_3
        for (let i = 0; i < fetchData[2].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[2][i].department_id) {
              return { ...department, sep_3: fetchData[2][i].sep_3 };
            }
            return department;
          });
          newData = newState;
        }
        //first month_4
        for (let i = 0; i < fetchData[3].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[3][i].department_id) {
              return { ...department, sep_4: fetchData[3][i].sep_4 };
            }
            return department;
          });
          newData = newState;
        }
        //first month_5
        for (let i = 0; i < fetchData[4].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[4][i].department_id) {
              return { ...department, sep_5: fetchData[4][i].sep_5 };
            }
            return department;
          });
          newData = newState;
        }
        //first month_total
        for (let i = 0; i < fetchData[5].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[5][i].department_id) {
              return { ...department, sep_total: fetchData[5][i].sep_total };
            }
            return department;
          });
          newData = newState;
        }

        // second month_1
        for (let i = 0; i < fetchData[6].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[6][i].department_id) {
              return { ...department, oct_1: fetchData[6][i].oct_1 };
            }
            return department;
          });
          newData = newState;
        }
        //second month_2
        for (let i = 0; i < fetchData[7].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[7][i].department_id) {
              return { ...department, oct_2: fetchData[7][i].oct_2 };
            }
            return department;
          });
          newData = newState;
        }
        //second month_3
        for (let i = 0; i < fetchData[8].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[8][i].department_id) {
              return { ...department, oct_3: fetchData[8][i].oct_3 };
            }
            return department;
          });
          newData = newState;
        }
        //second month_4
        for (let i = 0; i < fetchData[9].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[9][i].department_id) {
              return { ...department, oct_4: fetchData[9][i].oct_4 };
            }
            return department;
          });
          newData = newState;
        }
        //second month_5
        for (let i = 0; i < fetchData[10].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[10][i].department_id) {
              return { ...department, oct_5: fetchData[10][i].oct_5 };
            }
            return department;
          });
          newData = newState;
        }
        //second month_total
        for (let i = 0; i < fetchData[11].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[11][i].department_id) {
              return { ...department, oct_total: fetchData[11][i].oct_total };
            }
            return department;
          });
          newData = newState;
        }

        // third month_1
        for (let i = 0; i < fetchData[12].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[12][i].department_id) {
              return { ...department, nov_1: fetchData[12][i].nov_1 };
            }
            return department;
          });
          newData = newState;
        }
        //third month_2
        for (let i = 0; i < fetchData[13].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[13][i].department_id) {
              return { ...department, nov_2: fetchData[13][i].nov_2 };
            }
            return department;
          });
          newData = newState;
        }
        //third month_3
        for (let i = 0; i < fetchData[14].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[14][i].department_id) {
              return { ...department, nov_3: fetchData[14][i].nov_3 };
            }
            return department;
          });
          newData = newState;
        }
        //third month_4
        for (let i = 0; i < fetchData[15].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[15][i].department_id) {
              return { ...department, nov_4: fetchData[15][i].nov_4 };
            }
            return department;
          });
          newData = newState;
        }
        //third month_5
        for (let i = 0; i < fetchData[16].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[16][i].department_id) {
              return { ...department, nov_5: fetchData[16][i].nov_5 };
            }
            return department;
          });
          newData = newState;
        }
        //third month_total
        for (let i = 0; i < fetchData[17].length; i++) {
          const newState = newData.map((department) => {
            if (department.id === fetchData[17][i].department_id) {
              return { ...department, nov_total: fetchData[17][i].nov_total };
            }
            return department;
          });
          newData = newState;
        }

        setData(newData);
        // for(let i=0; i<fetchData.length; i++){
        //   for(let j=0; j<fetchData[i].length; j++){
        //     for(let k=1; k<28; k++){
        //       let newState = data.map(data=>{
        //         if(data.department_id===k){
        //           return {...data,}
        //         }
        //       })
        //     }
        //   }
        // }

        // for (let i = 0; i < data.length; i++) {
        //   setData((prevData) => {
        //     return [
        //       ...prevData,
        //       {
        //         // id: catchUp[i].catchup_id,
        //         // f_name: catchUp[i].f_name,
        //         department:data[i].department,
        //         sep_1:data[i].month_one_one,
        //         sep_2:data[i].month_one_two,
        //         sep_3:data[i].month_one_three,
        //         sep_4:data[i].month_one_four,
        //         sep_5:data[i].month_one_five,
        //       },
        //     ];
        //   });
        // }
      });

      return () => {
        //console.log("unmounted");
        effectRan.current = true;
      };
    }
  }, []);
  // useEffect(()=>{
  //   console.log(data)
  // },[data])

  return (
    <div>
      <DataSheetGrid value={data} onChange={setData} columns={columns} />
    </div>
  );
};

export default WeeklyAndMonthly;
