import { createContext, useState, useEffect, useRef } from "react";
import Axios from "axios";

export const AuthContext = createContext({
  id: "",
  status: false,
  privilege: false,
  onLogout: () => {},
  //onLogin: (id, status, privilege) => {},
});

export const AuthContextProvider = (props) => {
  const [authState, setAuthState] = useState({
    id: "",
    status: false,
    privilege: false,
  });

  /*   const logoutHandler = () => {
    setAuthState({
      id: "",
      status: false,
      privilege: false,
    });
  };
 */

  const effectRan = useRef(false);
  useEffect(() => {
    if (effectRan.current === false) {
      Axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/signIn`).then(
        (response) => {
          console.log("csrf and credentials are running...");
          console.log(response.data);
          Axios.defaults.withCredentials = true;
          Axios.defaults.headers.post["X-CSRF-Token"] = response.data.csrfToken;
          Axios.defaults.headers.delete["X-CSRF-Token"] =
            response.data.csrfToken;
          Axios.defaults.headers.patch["X-CSRF-Token"] =
            response.data.csrfToken;
          //error response
          if (response.data.error) {
            setAuthState({
              id: response.data.id,
              status: response.data.authFlag,
              privilege: response.data.privilege,
            });
          } else {
            //localstorage set here
            setAuthState({
              id: response.data.id,
              status: response.data.authFlag,
              privilege: response.data.privilege,
            });
          }
        }
      );
      return () => {
        //console.log("unmounted");
        effectRan.current = true;
      };
    }
  }, []);

  const onLogout = (event) => {
    event.preventDefault();
    Axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/auth/logOut`)
      .then((response) => {
        console.log(response.data.success);
        setAuthState({
          id: "",
          status: false,
          privilege: false,
        });
        if (response.data.logOut === false) {
          //window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <AuthContext.Provider value={{ authState, setAuthState, onLogout }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
