import React, { useContext, Fragment } from "react";
import Axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  BiError,
  BiMessageCheck,
  //BiMinusCircle,
  //BiCheck,
} from "react-icons/bi";
import { toast } from "react-toastify";

import { AuthContext } from "../../../../../../../Context/AuthContext";

function DeleteTablePrompt(props) {
  let navigate = useNavigate();
  const { authState } = useContext(AuthContext);

  const deleteTable = () => {
    Axios.delete(
      `${process.env.REACT_APP_SERVER_DOMAIN}/checkBox/reset-category/${props.catchup_id}`,
      {
        data: {
          //user_id: authState.id,
          category_id: props.category_id,
          category_db: props.checkbox_question_db,
          catchup_id: props.catchup_id,
        },
      }
    )
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
          return toast.error(
            <Fragment>
              <BiError /> <span>{response.data.error}</span>
            </Fragment>
          );
        } else {
          return toast.success(
            <Fragment>
              <BiMessageCheck /> <span>{response.data.success}</span>
            </Fragment>
          );
        }
      })
      .catch(() => {
        navigate("/403");
      });
    //console.log(props.catchup_id);
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Reset categorization for question {props.category_id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Modal.Title>Reset this</Modal.Title> */}
        <h1 className="text-center">Catch up entry ID: {props.catchup_id}</h1>
        <div className="d-flex justify-content-evenly mt-4">
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={props.handleClose && deleteTable}>
            Confirm Reset
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteTablePrompt;
